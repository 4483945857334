import React, { useState, useEffect } from "react";
import "./TemplateListBoxTable.scss";
import { ListBox } from "primereact/listbox";
import { TemplateService } from "../../assets/service/TemplateService";
import { Badge } from "primereact/badge";
import { Fieldset } from "primereact/fieldset";

export const TemplateListBoxTable = ({
  onTemplateListChange,
  isClickCreateNewTemplate,
  isClickEditTemplate,
  setIsClickEditTemplate,
  tmpSelected,
  submittedCreateTemplateAsCopy,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [templateDatas, setTemplateDatas] = useState(null);

  useEffect(() => {
    if (
      submittedCreateTemplateAsCopy ||
      isClickEditTemplate ||
      isClickCreateNewTemplate === false
    )
      TemplateService.getAllNameTemplates().then((data) =>
        setTemplateDatas(data)
      );
  }, [
    submittedCreateTemplateAsCopy,
    isClickEditTemplate,
    isClickCreateNewTemplate,
  ]);

  useEffect(() => {
    setSelectedTemplate(null);
    if (!isClickCreateNewTemplate) {
      console.log(tmpSelected);
      setSelectedTemplate({
        id: tmpSelected?.id,
        label: tmpSelected?.attributes.name,
      });
    }
  }, [tmpSelected, isClickCreateNewTemplate]);

  const onListBoxChange = (data) => {
    console.log(data);
    setIsClickEditTemplate(false);
    onTemplateListChange(data);
    setSelectedTemplate(data);
  };

  return (
    <div className="template-field-set-table">
      <Fieldset legend="Template" toggleable>
        <ListBox
          filter
          value={selectedTemplate}
          onChange={(e) => {
            onListBoxChange(e.value);
          }}
          options={templateDatas}
          optionLabel="label"
          className="w-full md:w-15rem"
          listStyle={{ maxHeight: "640px" }}
        />
      </Fieldset>
    </div>
  );
};
