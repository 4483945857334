import axios from "axios";

export const loginUser = async (email, password) => {
  console.log(process.env.REACT_APP_API_URL);
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/api/auth/local",
      {
        identifier: email,
        password: password,
      },
      {
        withCredentials: true,
      }
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};
