import React, { useRef, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { Avatar } from "primereact/avatar";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import logoImage from "../../assets/images/NTT-logo-white.png";
import { loginUser } from "../../assets/service/auth/AuthService";
import { AuthContext } from "../../assets/service/auth/AuthContext";

export const Login = ({}) => {
  const { login } = useContext(AuthContext);
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    try {
      const loginData = await loginUser(data.email, data.password);
      login(loginData.user, loginData.jwt);

      toast.current.show({
        severity: "success",
        summary: "Form Submitted",
        detail: getValues("email") + getValues("password"),
      });

      setLoading(false);
      reset();
    } catch (error) {
      console.error(error);
      let errorText;
      switch (error.code) {
        case "ERR_BAD_REQUEST":
          errorText = "Username or Password Invalid";
          break;
        default:
          break;
      }

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorText,
        life: 3000,
      });

      setLoading(false);
    }
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <div className="login-container" key={"login"}>
      <div className="login-wrapper">
        <div className="logo-wrapper">
          <Avatar image={logoImage} size="xlarge" shape="circle" />
        </div>
        <div className="input-wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column ">
            <Toast ref={toast} />
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required." }}
              render={({ field, fieldState }) => (
                <>
                  <span className="p-input-icon-left">
                    <i className="pi pi-user" style={{ color: "#f6f6f6bc" }} />
                    <InputText
                      placeholder="Username"
                      id={field.name}
                      {...field}
                      className={[
                        "p-inputtext-lg login-input",
                        classNames({ "p-invalid": fieldState.error }),
                      ]}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: "Password is required." }}
              render={({ field, fieldState }) => (
                <>
                  <span className="p-input-icon-left">
                    <i className="pi pi-lock" style={{ color: "#f6f6f6bc" }} />
                    <Password
                      id={field.name}
                      {...field}
                      inputRef={field.ref}
                      placeholder="Password"
                      className={[
                        "p-inputtext-lg login-input-password",
                        classNames({ "p-invalid": fieldState.error }),
                      ]}
                      feedback={false}
                      toggleMask
                    />
                  </span>

                  {getFormErrorMessage(field.name)}
                </>
              )}
            />

            <Button
              label="Log In"
              type="submit"
              className="login-btn"
              loading={loading}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {};

Login.defaultProps = {};
