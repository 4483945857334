import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, Link, Outlet } from "react-router-dom";
import "./NavBar.scss";
import "primeicons/primeicons.css";
import { Tooltip } from "primereact/tooltip";
import NTT_Logo from "../../../src/assets/images/NTT-logo.png";
import { DatabasStateService } from "../../assets/service/DatabaseStateService";
import { AuthContext } from "../../assets/service/auth/AuthContext";

export const NavBar = ({ children }) => {
  const { logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(true);
  const [DB, setDB] = useState();
  useEffect(() => {
    setTimeout(() => {
      DatabasStateService.getDBState().then((data) => setDB(data.attributes));
    }, 100);
  }, []);

  const toggle = () => setIsOpen(!isOpen);

  const menuItems = [
    {
      path: "/",
      name: "List report",
      icon: <i style={{ fontSize: "25px" }} className="pi pi-list"></i>,
    },
    {
      path: "/manual",
      name: "Manual report",
      icon: <i style={{ fontSize: "25px" }} className="pi pi-pencil"></i>,
    },
    {
      path: "/template",
      name: "Template report",
      icon: <i style={{ fontSize: "25px" }} className="pi pi-copy"></i>,
    },
    {
      path: "/log",
      name: "Log",
      icon: <i style={{ fontSize: "25px" }} className="pi pi-database"></i>,
    },
    {
      path: "/logout",
      name: "Logout",
      icon: <i style={{ fontSize: "25px" }} className="pi pi-sign-out"></i>,
      onClick: logout,
    },
  ];

  return (
    <div className="sidebar-container">
      <div
        style={{
          width: isOpen ? "300px" : "65px",
          minWidth: isOpen ? "250px" : "0px",
        }}
        className="sidebar"
      >
        <div className="top_section">
          <div className="logo">
            <img src={NTT_Logo} alt=""></img>
          </div>
          <label
            style={{
              transition: "display 0.3s ease-in-out",
              display: isOpen ? "inline" : "none",
            }}
            className="ntt-text-logo"
          >
            NTT&nbsp;Group
          </label>
        </div>
        <div className="list-container">
          {menuItems.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={`link${item.name === "Logout" ? " logout-link" : ""}`}
              activeclassname="active"
              onClick={item.onClick}
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name.split(" ").map((word, i, arr) => (
                  <React.Fragment key={i}>
                    {word}
                    {i !== arr.length - 1 && <>&nbsp;</>}
                  </React.Fragment>
                ))}
              </div>
            </NavLink>
          ))}
        </div>
      </div>
      <div className="content">
        <div className="topbar">
          <div className="bars">
            <i
              className="pi pi-align-justify"
              style={{ fontSize: "25px" }}
              onClick={toggle}
            ></i>
            <div className="flex gap-2">
              <Tooltip target=".custom-target-icon" />
              <div
                className={`sql-icon-wrapper select-none ${
                  DB?.db1Status === true ? "" : "blink"
                }`}
              >
                <span
                  className=" pi pi-database"
                  style={{
                    fontSize: "35px",
                    color: DB?.db1Status === true ? "green" : "red",
                  }}
                ></span>
                <span
                  className="absolute custom-target-icon "
                  data-pr-tooltip={`SQL Activated in secondary ${
                    DB?.db1Status === true ? "(online)" : "(offline)"
                  }`}
                  data-pr-position="left"
                  data-pr-at="left center"
                  data-pr-my="right center"
                >
                  I
                </span>
                {DB?.dbSelected === "db1" && (
                  <span
                    className="absolute pi pi-check-circle check"
                    style={{ color: "green" }}
                  ></span>
                )}
              </div>
              <div
                className={`sql-icon-wrapper select-none ${
                  DB?.db2Status === true ? "" : "blink"
                }`}
              >
                <span
                  className=" pi pi-database"
                  style={{
                    fontSize: "35px",
                    color: DB?.db2Status === true ? "green" : "red",
                  }}
                ></span>
                <span
                  className="absolute custom-target-icon"
                  // data-pr-tooltip="Inavtive SQL in Primary (offline)"
                  data-pr-tooltip={`SQL Activated in secondary ${
                    DB?.db2Status === true ? "(online)" : "(offline)"
                  }`}
                  data-pr-position="left"
                  data-pr-at="left center"
                  data-pr-my="right center"
                >
                  II
                </span>
                {DB?.dbSelected === "db2" && (
                  <span
                    className="absolute pi pi-check-circle check"
                    style={{ color: "green" }}
                  ></span>
                )}
              </div>
            </div>
          </div>
        </div>
        <main>{children}</main>
      </div>
    </div>
  );
};
