import axios from "axios";
export const LogService = {
  // GET List
  getLogsData(resolve, reject, page, pageSize) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/sql-logs?sort=id&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getLogs(page, pageSize) {
    return new Promise((resolve, reject) => {
      this.getLogsData(resolve, reject, page, pageSize);
    });
  },

  // GET List by filter
  getLogsDataByFilter(resolve, reject, page, pageSize, startDate, endDate) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/sql-logs?sort=id&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[createdAt][$gte]=${startDate}&filters[createdAt][$lte]=${endDate}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getLog(page, pageSize, startDate, endDate) {
    return new Promise((resolve, reject) => {
      this.getLogsDataByFilter(
        resolve,
        reject,
        page,
        pageSize,
        startDate,
        endDate
      );
    });
  },
};
