import { findNodeByKey } from "../components/sidebar/query-report/utils";
export const compareObjects = (obj1, obj2) => {
  if (obj1 === null || obj2 === null) return true;
  // Compare keys
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare values
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const findDifferences = (obj1, obj2) => {
  const differences = {};

  for (let key in obj1) {
    if (obj1[key] !== obj2[key]) {
      differences[key] = {
        oldValue: obj1[key],
        newValue: obj2[key],
      };
    }
  }

  for (let key in obj2) {
    if (!obj1.hasOwnProperty(key)) {
      differences[key] = {
        oldValue: undefined,
        newValue: obj2[key],
      };
    }
  }

  return differences;
};

export const transformObject = (obj) => {
  const transformedQueryData = obj.queryData.map((item) => item.name);
  return {
    ...obj,
    queryData: transformedQueryData,
  };
};

export const isValidDate = (year, month, day) => {
  var d = new Date(year, month, day);
  if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
    return true;
  }
  return false;
};

export const getChildList = (data, queryDatas) => {
  const selectedDataArray = [];

  // Iterate over the keys in selectedNodes
  Object.keys(data).forEach((key) => {
    // Check if the node is checked
    if (data[key].checked) {
      // Find the corresponding node in queryDatas
      const node = findNodeByKey(queryDatas, key);

      // Add the node to the selectedDataArray if found
      if (node && !node.data.includes("Folder")) {
        selectedDataArray.push(node);
      }
    }
  });

  return selectedDataArray;
};
