import React from "react";
import "./FilterTableHeader.scss";

import { Fieldset } from "primereact/fieldset";
import { Divider } from "primereact/divider";
import { Search } from "./search/Search";
import { DateRange } from "./date-range/DateRange";

export const FilterTableHeader = ({ handleDateRangeChange }) => {
  return (
    <div>
      <Fieldset
        toggleable
        className="fieldset-container"
        expandIcon={<i className="pi pi-search-plus"></i>}
        collapseIcon={<i className="pi pi-search-minus"></i>}
      >
        <DateRange onDateRangeChange={handleDateRangeChange}></DateRange>
      </Fieldset>
    </div>
  );
};
