import React, { useState, useEffect } from "react";
import "./QueryListBoxTable.scss";
import { Badge } from "primereact/badge";
import { Tree } from "primereact/tree";

export const QueryListBoxTable = ({
  selectedQueryDatasLength,
  queryDatas,
  selectedQueryDatas,
  onQueryDataChange,
  maxHeight,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(null);

  useEffect(() => {
    setSelectedKeys(selectedQueryDatas);
    // Iterate over the keys in selectedNodes
    // if (selectedQueryDatas && queryDatas !== null) {
    //   setSelectedDatas(getChildList(selectedQueryDatas, queryDatas));
    // }
  }, [selectedQueryDatas, queryDatas]);

  return (
    <div className="query-data-wrapper card ">
      <div className="query-data-header text-center">
        Query data{" "}
        <Badge
          value={selectedQueryDatasLength}
          size="xlarge"
          severity="success"
        ></Badge>
      </div>
      <Tree
        value={queryDatas}
        selectionMode="checkbox"
        selectionKeys={selectedKeys}
        onSelectionChange={(e) => {
          onQueryDataChange(e.value);
        }}
        className="w-full list-box "
        filter
        filterMode="lenient"
        filterPlaceholder="Search"
      />
    </div>
  );
};
