import React, { useEffect } from "react";
import { FullDateRange } from "../../../src/components/table-header/filter/date-range/FullDateRange";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { QueryListBoxTable } from "../../components/query-list-box/QueryListBoxTable";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export const OldTemplate = ({
  queryDatas,
  selectedtemplate,
  rawQueryData,
  onQueryDataChange,
  reportName,
  setReportName,
  validationState,
  handleDateRangeChange,
  selectedRollUpCal,
  selectedRollUp,
  handleRollUpChange,
  selectedQueryDatas,
  exportQueryDatas,
  handleSelectionChange,
  actionBodyTemplate,
  setVisiblePreviewTrend,
  openCreateTemplateAsCopy,
  handleClickEditTemplate,
  handleQueryReportClick,
}) => {
  return (
    <div
      className={`card-template flex gap-3 flex-column ${
        selectedtemplate ? "expanded" : ""
      }`}
    >
      <div>
        <div className="template-name">{selectedtemplate.attributes.name}</div>
        <div className="template-description">
          {selectedtemplate.attributes.description}
        </div>
      </div>
      <div className="flex gap-3 ">
        <QueryListBoxTable
          selectedQueryDatasLength={selectedQueryDatas?.length}
          queryDatas={queryDatas}
          selectedQueryDatas={rawQueryData}
          onQueryDataChange={onQueryDataChange}
          maxHeight={"550px"}
        ></QueryListBoxTable>
        <div className="manual-box card w-full flex flex-column gap-3">
          {/* Report Name */}
          <div className="description-container">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-file-edit" />
              <InputText
                placeholder="Name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                className={[
                  "w-full",
                  "p-inputtext-lg",
                  classNames({
                    "p-invalid": validationState.isReportNameInvalid,
                  }),
                ]}
              />
            </span>
          </div>

          {/* Date Range , AVG MIN MAX */}
          <div className="manual-box-header flex justify-content-between align-items-center">
            <FullDateRange
              onDateRangeChange={handleDateRangeChange}
              isDateRangeInvalid={validationState.isDateRangeInvalid}
              isRollupInvalid={validationState.isRollupInvalid}
              isRollupCalInvalid={
                selectedRollUpCal === null && selectedRollUp !== "None"
              }
              onRollUpChange={handleRollUpChange}
              selectedTemplateTimeRange={selectedtemplate.attributes}
            ></FullDateRange>
          </div>

          {/* Query Data table */}
          <div className="manual-data-table-container">
            <DataTable
              className="manual-data-table"
              value={selectedQueryDatas}
              selection={exportQueryDatas}
              onSelectionChange={handleSelectionChange}
              dataKey="key"
              tableStyle={{ minWidth: "50rem" }}
              emptyMessage="Waiting for select query data"
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
              // Scroll
              scrollable
              scrollHeight="420px"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                className={` ${
                  validationState.isTableInvalid ? "invalid" : ""
                }`}
                headerClassName={` ${
                  validationState.isTableInvalid ? "invalid" : ""
                }`}
              ></Column>
              <Column
                header="No."
                headerStyle={{ width: "3rem" }}
                body={(data, options) => options.rowIndex + 1}
              ></Column>
              <Column field="data" header="Data name"></Column>
              <Column
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "5rem" }}
              ></Column>
            </DataTable>
          </div>

          {/* BTN preview trend */}
          <Button
            tooltip={
              exportQueryDatas?.length < 1
                ? "Please select query data at least 1 item "
                : ""
            }
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              showOnDisabled: true,
            }}
            onClick={() => setVisiblePreviewTrend(true)}
            label="Preview trend"
            severity="info"
            className="w-full"
            disabled={exportQueryDatas?.length < 1}
          />

          {/* BTN create as New Template */}
          <Button
            tooltip={
              exportQueryDatas?.length < 1
                ? "Please select query data at least 1 item"
                : ""
            }
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              showOnDisabled: true,
            }}
            label="Create as New Template"
            className="w-full"
            onClick={() => openCreateTemplateAsCopy()}
            disabled={exportQueryDatas?.length < 1}
          />
          <Button
            severity="warning"
            label="Edit Template"
            onClick={() => handleClickEditTemplate(selectedtemplate)}
          />

          {/* BTN Query Report */}
          <div className="query-report-container flex flex-auto justify-content-end align-items-end">
            <div>
              <Button
                label="Query report"
                severity="success"
                size="large"
                onClick={handleQueryReportClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
