import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import Chart from "react-apexcharts";
import { QueryDataService } from "../../../assets/service/DataService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { formatDateYMD, formatDateDMY, getAggregationType } from "./utils";
import { ExportButtonsTable, ExportButtonsChart } from "./ExportButtons";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import excelfile from "../../../assets/files/Report.xlsx";
import { DateService } from "../../../assets/service/DateService";

export const QueryReportSideBar = ({
  visibleQueryReport,
  setVisibleQueryReport,
  selectedDateRange,
  dataTypeExport,
  reportName,
  selectedRollUp,
  selectedRollUpCal,
  exportQueryDatas,
  templateName,
}) => {
  const navigate = useNavigate();
  const dt = useRef(null);
  const chartRef = useRef(null);
  const [chart, setChart] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: [
        "#96D1F5",
        "#6A9DBF",
        "#4E6C83",
        "#8C9B6D",
        "#A77D6B",
        "#B2A3AC",
        "#6C7D8E",
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: selectedRollUpCal ? selectedRollUpCal : "",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        title: {
          text: "Date",
        },
      },
      yaxis: {
        title: {
          text: "Value",
        },
      },
    },
  });

  const [datasTable, setDatasTable] = useState();
  const [columns, setColumns] = useState();
  const [exportColumns, setExportColumn] = useState();

  useEffect(() => {
    const uniqueNames = Array.from(
      new Set(
        datasTable?.reduce((acc, entry) => {
          const keys = Object.keys(entry).filter((key) => key !== "timestamp");
          return acc.concat(keys);
        }, [])
      )
    );

    const cols = [
      { field: "timestamp", header: "Timestamp" },
      ...uniqueNames.map((name) => ({ field: name, header: name })),
    ];

    setExportColumn((prev) =>
      cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }))
    );
  }, [datasTable]);

  useEffect(() => {
    if (visibleQueryReport === true && exportQueryDatas.length > 0) {
      console.log(exportQueryDatas);
      console.log(selectedDateRange);
      console.log(reportName, selectedRollUp, selectedRollUpCal);
      fetchData();
    }
  }, [visibleQueryReport, exportQueryDatas]);

  const fetchData = async () => {
    console.log("fechData");
    let ids = exportQueryDatas.map((data) => data.id);
    console.log(ids);
    try {
      QueryDataService.postQueryReport(
        reportName,
        templateName ? templateName : null,
        selectedDateRange,
        selectedRollUp,
        selectedRollUpCal,
        ids
      )
        .then((result) => {
          setChart((prevOptions) => ({
            ...prevOptions,
            series: result.series,
            options: {
              ...prevOptions.options,
              xaxis: {
                categories: result.categories.map(
                  (entry) =>
                    DateService.changeDateStringToDateFormal(entry) +
                    " " +
                    DateService.changeDateStringToTimeFormal(entry)
                ),
                ...prevOptions.options.xaxis,
              },
            },
          }));

          setColumns((prev) => [
            { field: "timestamp", header: "Timestamp" },
            ...result.series.map((series) => ({
              field: series.name,
              header: series.name,
            })),
          ]);

          setDatasTable((prev) =>
            result.categories.map((category, rowIndex) => {
              const row = {
                timestamp:
                  DateService.changeDateStringToDateFormal(category) +
                  " " +
                  DateService.changeDateStringToTimeFormal(category),
              };
              result.series.forEach((series) => {
                row[series.name] = series.data[rowIndex];
              });
              return row;
            })
          );
        })
        .catch((error) => {
          console.error("Error Query Report:", error);
        });
    } catch (error) {
      console.error(`Error fetching data for  error`);
      return null;
    }
  };

  // Export For table
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, datasTable);
        doc.save("products.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(datasTable);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "products");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  // Export for Chart
  const exportPNG = () => {
    if (chartRef) {
      const paper = chartRef.current.chart.w.globals.dom.Paper;
      const svg = paper.svg();
      saveAs(new Blob([svg], { type: "image/svg+xml" }), "report.svg");
    }
  };
  const exportSVG = () => {
    if (chartRef) {
      const chartEl = chartRef.current.chart;
      chartEl.dataURI().then(({ imgURI }) => {
        saveAs(
          imgURI,

          "report.png"
        );
      });
    }
  };

  // handle onClickExportTable
  const onClickExportTable = () => {
    console.log(reportName);
    const link = document.createElement("a");
    link.href = excelfile;
    link.download = reportName + "_" + formatDateDMY(new Date()) + ".xlsx";
    link.click();
    // setVisibleQueryReport(false);
    // navigate("/list");
  };

  return (
    <Sidebar
      className="query-report-side-bar-container"
      fullScreen
      visible={visibleQueryReport}
      position="right"
      onHide={() => setVisibleQueryReport(false)}
      header={<div className="header text-6xl">{reportName}</div>}
    >
      {/* wrapper of date info */}
      <div className="flex justify-content-between align-items-end gap-5 mb-3 text-xl">
        <div className="flex gap-4">
          <div className="flex justify-content-center align-items-center gap-2">
            <label className="text-l">From : </label>
            <div>{formatDateDMY(selectedDateRange?.fromDate)}</div>
          </div>
          <div className="flex justify-content-center align-items-center gap-2">
            <label className="text-l">To : </label>
            <div>{formatDateDMY(selectedDateRange?.toDate)}</div>
          </div>
          <div className="flex justify-content-center align-items-center gap-2">
            <label className="text-l">Rollup : </label>
            <div>{selectedRollUp}</div>
          </div>
          {selectedRollUp !== "None" && (
            <div className="flex justify-content-center align-items-center gap-2">
              <label className="text-l">Type : </label>
              <div>{getAggregationType(selectedRollUpCal)}</div>
            </div>
          )}
        </div>
      </div>

      {/* wrapper of query data arrays */}
      <div className="flex overflow-scroll flex-wrap justify-content-center align-items-start h-auto">
        {exportQueryDatas?.map((item, index) => (
          <span className="query-data-dialog text-center" key={index}>
            {item.data}
          </span>
        ))}
      </div>

      <div className="formgrid grid">
        <div className="field col-12 md:col-6">
          {/* <ExportButtonsChart
            exportPNG={exportPNG}
            exportSVG={exportSVG}
          ></ExportButtonsChart> */}
          <Chart
            ref={chartRef}
            options={chart.options}
            series={chart.series}
            type="line"
            width="100%"
          />
        </div>
        <div className="field col-12 md:col-6">
          <DataTable
            ref={dt}
            header={
              <ExportButtonsTable
                exportCSV={exportCSV}
                exportExcel={exportExcel}
                exportPdf={exportPdf}
              />
            }
            value={datasTable}
            tableStyle={{ minWidth: "50rem" }}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Query datas"
            scrollable
            scrollHeight="500px"
          >
            {columns?.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                style={{ maxWidth: "200px", textAlign: "center" }}
                headerStyle={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            ))}
          </DataTable>
        </div>
        <div className="field col-12 flex justify-content-end gap-3">
          <Button
            label="Back"
            severity="secondary"
            onClick={() => setVisibleQueryReport(false)}
          />
          <Button
            label="Export Table"
            severity="success"
            onClick={onClickExportTable}
          />
        </div>
      </div>
    </Sidebar>
  );
};
