import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import "./DateRange.scss";
import { isValidDate } from "../../../../assets/utils";

export const DateRange = ({
  onDateRangeChange,
  isInvalid,
  selectedTemplateTimeRange,
}) => {
  const [selectedTypeRange, setSelectedTypeRange] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [dayOfFromDate, setDayOfFromDate] = useState(null);
  const [monthOfFromDate, setMonthOfFromDate] = useState(null);
  const [yearOfFromDate, setYearOfFromDate] = useState(null);

  const [dayOfToDate, setDayOfToDate] = useState(null);
  const [monthOfToDate, setMonthOfToDate] = useState(null);
  const [yearOfToDate, setYearOfToDate] = useState(null);

  const [isToDateOlder, setIsToDateOlder] = useState(false);
  const [isToDateInvalid, setIsToDateInvalid] = useState(false);
  const [isFromDateInvalid, setIsFromDateInvalid] = useState(false);

  const days = Array.from({ length: 31 }, (_, index) => ({
    name: `${index + 1}`,
    numOfDay: `${index + 1}`,
  }));

  const months = Array.from({ length: 12 }, (_, index) => ({
    name: new Date(0, index).toLocaleString("en-US", { month: "short" }),
    numOfMonth: `${index}`,
  }));

  const startYear = 2010;
  const endYear = 2036;

  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => ({
    name: `${startYear + index}`,
    numOfYear: `${startYear + index}`,
  }));

  const chartDatas = [
    "Date Range",
    "Today",
    "Yesterday",
    "Week-To-Date",
    "Last Week",
    "Last 7 Days",
    "Month-To-Date",
    "Last Month",
  ].map((label) => ({
    label,
  }));

  const handleDateRangeChange = () => {
    const dateRange = { fromDate, toDate };
    // Check if fromDate is a valid date
    if (isFromDateInvalid) {
      return;
    }
    // Check if toDate is a valid date
    if (isToDateInvalid) {
      return;
    }
    setIsToDateOlder(false);
    // Check if toDate is older than fromDate
    if (fromDate && toDate && toDate < fromDate) {
      // Handle the case where toDate is older than fromDate, for example, show an error message or handle it accordingly
      // console.error("Error: toDate must be equal to or later than fromDate");
      setIsToDateOlder(true);

      // Optionally, you can reset toDate to fromDate to avoid inconsistencies
      // setToDate(fromDate);
    } else {
      // If toDate is equal to or later than fromDate, proceed with the date range change
      setIsToDateOlder(false);
      onDateRangeChange(dateRange);
    }
  };

  const setDatesForTimeRange = (range) => {
    const today = new Date();
    switch (range) {
      case "Date Range":
        // Set from date to null
        setFromDate(null);
        setToDate(null);

        // Set all dropdown day month year to null
        if (!selectedTemplateTimeRange) {
          console.log("CLEAR ALL Time Range");
          setDayOfFromDate(null);
          setMonthOfFromDate(null);
          setYearOfFromDate(null);
          setDayOfToDate(null);
          setMonthOfToDate(null);
          setYearOfToDate(null);
        }

        break;
      case "Today":
        setFromDate(today);
        setToDate(today);
        updateDate(
          today,
          setDayOfFromDate,
          setMonthOfFromDate,
          setYearOfFromDate
        );
        updateDate(today, setDayOfToDate, setMonthOfToDate, setYearOfToDate);
        break;
      case "Yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        setFromDate(yesterday);
        setToDate(yesterday);
        updateDate(
          yesterday,
          setDayOfFromDate,
          setMonthOfFromDate,
          setYearOfFromDate
        );
        updateDate(
          yesterday,
          setDayOfToDate,
          setMonthOfToDate,
          setYearOfToDate
        );
        break;
      case "Week-To-Date":
        const firstDayOfWeek = new Date(today);
        firstDayOfWeek.setDate(today.getDate() - today.getDay());
        setFromDate(firstDayOfWeek);
        setToDate(today);
        updateDate(
          firstDayOfWeek,
          setDayOfFromDate,
          setMonthOfFromDate,
          setYearOfFromDate
        );
        updateDate(today, setDayOfToDate, setMonthOfToDate, setYearOfToDate);
        break;
      case "Last Week":
        const lastSunday = new Date(today);
        lastSunday.setDate(today.getDate() - today.getDay() - 7);
        const lastSaturday = new Date(today);
        lastSaturday.setDate(today.getDate() - today.getDay() - 1);
        setFromDate(lastSunday);
        setToDate(lastSaturday);
        updateDate(
          lastSunday,
          setDayOfFromDate,
          setMonthOfFromDate,
          setYearOfFromDate
        );
        updateDate(
          lastSaturday,
          setDayOfToDate,
          setMonthOfToDate,
          setYearOfToDate
        );
        break;
      case "Last 7 Days":
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        setFromDate(sevenDaysAgo);
        setToDate(today);
        updateDate(
          sevenDaysAgo,
          setDayOfFromDate,
          setMonthOfFromDate,
          setYearOfFromDate
        );
        updateDate(today, setDayOfToDate, setMonthOfToDate, setYearOfToDate);
        break;
      case "Month-To-Date":
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        setFromDate(firstDayOfMonth);
        setToDate(today);
        updateDate(
          firstDayOfMonth,
          setDayOfFromDate,
          setMonthOfFromDate,
          setYearOfFromDate
        );
        updateDate(today, setDayOfToDate, setMonthOfToDate, setYearOfToDate);
        break;
      case "Last Month":
        const firstDayOfLastMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setFromDate(firstDayOfLastMonth);
        setToDate(lastDayOfLastMonth);
        updateDate(
          firstDayOfLastMonth,
          setDayOfFromDate,
          setMonthOfFromDate,
          setYearOfFromDate
        );
        updateDate(
          lastDayOfLastMonth,
          setDayOfToDate,
          setMonthOfToDate,
          setYearOfToDate
        );
        break;
      default:
        // Set from date to null
        setFromDate(null);
        setToDate(null);

        // Set all dropdown day month year to null
        setDayOfFromDate(null);
        setMonthOfFromDate(null);
        setYearOfFromDate(null);
        setDayOfToDate(null);
        setMonthOfToDate(null);
        setYearOfToDate(null);
        break;
    }
  };

  useEffect(() => {
    setDatesForTimeRange(selectedTypeRange?.label);
  }, [selectedTypeRange]);

  useEffect(() => {
    if (
      (fromDate !== null && toDate !== null) ||
      selectedTypeRange?.label === "Date Range"
    ) {
      handleDateRangeChange();
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (dayOfFromDate && monthOfFromDate && yearOfFromDate) {
      setIsFromDateInvalid(false);
      if (
        isValidDate(
          yearOfFromDate.numOfYear,
          monthOfFromDate.numOfMonth,
          dayOfFromDate.numOfDay
        )
      ) {
        const newFromDate = new Date(
          parseInt(yearOfFromDate.numOfYear),
          parseInt(monthOfFromDate.numOfMonth),
          parseInt(dayOfFromDate.numOfDay) + 1
        );
        setFromDate(newFromDate);
      } else {
        setFromDate(null);
        setIsFromDateInvalid(true);
      }
    }
  }, [dayOfFromDate, monthOfFromDate, yearOfFromDate]);

  useEffect(() => {
    if (dayOfToDate && monthOfToDate && yearOfToDate) {
      setIsToDateInvalid(false);
      if (
        isValidDate(
          yearOfToDate.numOfYear,
          monthOfToDate.numOfMonth,
          dayOfToDate.numOfDay
        )
      ) {
        const newToDate = new Date(
          parseInt(yearOfToDate.numOfYear),
          parseInt(monthOfToDate.numOfMonth),
          parseInt(dayOfToDate.numOfDay) + 1
        );
        setToDate(newToDate);
      } else {
        setToDate(null);
        setIsToDateInvalid(true);
      }
    }
  }, [dayOfToDate, monthOfToDate, yearOfToDate]);

  useEffect(() => {
    if (selectedTemplateTimeRange) {
      console.log(selectedTemplateTimeRange);
      setSelectedTypeRange({ label: "Date Range" });
      updateDate(
        new Date(selectedTemplateTimeRange.startDate),
        setDayOfFromDate,
        setMonthOfFromDate,
        setYearOfFromDate
      );
      updateDate(
        new Date(selectedTemplateTimeRange.endDate),
        setDayOfToDate,
        setMonthOfToDate,
        setYearOfToDate
      );
    }
  }, [selectedTemplateTimeRange]);

  const updateDate = (date, setDay, setMonth, setYear) => {
    if (date) {
      console.log({
        name: date.getDate().toString(),
        numOfDay: date.getDate().toString(),
      });
      setDay({
        name: date.getDate().toString(),
        numOfDay: date.getDate().toString(),
      });
      setMonth({
        name: new Date(date).toLocaleString("en-US", { month: "short" }),
        numOfMonth: date.getMonth().toString(),
      });
      setYear({
        name: date.getFullYear().toString(),
        numOfYear: date.getFullYear().toString(),
      });
    }
  };

  return (
    <div className="filter-container flex flex-column gap-2">
      <div className="select-wrapper">
        <span className="p-float-label">
          <Dropdown
            value={selectedTypeRange}
            onChange={(e) => setSelectedTypeRange(e.value)}
            options={chartDatas}
            optionLabel="label"
            placeholder="Time Range"
            className={["md:w-13rem", classNames({ "p-invalid": isInvalid })]}
          />
          <label htmlFor="dd-city">Time Range</label>
        </span>
      </div>

      <div className="flex calendar-wrapper">
        {/* From Date Wrapper */}
        <div
          className={[
            classNames({
              "date-picker-wrapper": true,
              "error-from-date-background": isFromDateInvalid,
            }),
          ]}
        >
          <div className="flex justify-content-center align-items-center gap-2">
            <label className="text-l"> From : </label>
            <Dropdown
              value={dayOfFromDate}
              onChange={(e) => setDayOfFromDate(e.value)}
              options={days}
              optionLabel="name"
              placeholder="Day"
              className="w-full md:w-6rem"
              disabled={selectedTypeRange?.label !== "Date Range"}
            />
            <Dropdown
              value={monthOfFromDate}
              onChange={(e) => setMonthOfFromDate(e.value)}
              options={months}
              optionLabel="name"
              placeholder="Month"
              className="w-full md:w-7rem"
              disabled={selectedTypeRange?.label !== "Date Range"}
            />
            <div className="year-dropdown-calendar-btn-wrapper">
              <Dropdown
                value={yearOfFromDate}
                onChange={(e) => setYearOfFromDate(e.value)}
                options={years}
                optionLabel="name"
                placeholder="Year"
                className="w-full md:w-6rem"
                disabled={selectedTypeRange?.label !== "Date Range"}
              />
              <Calendar
                dateFormat="dd/mm/yy"
                value={fromDate}
                onChange={(e) => {
                  // setFromDate(e.value);
                  updateDate(
                    e.value,
                    setDayOfFromDate,
                    setMonthOfFromDate,
                    setYearOfFromDate
                  );
                }}
                disabled={selectedTypeRange?.label !== "Date Range"}
                className={[
                  "from-calendar",
                  classNames({ "p-invalid": isInvalid }),
                ]}
                inputId="dateFromID"
              />
              <label
                className={[
                  classNames({
                    "calendar-end-of-dropdown-btn": true,
                    disabled: selectedTypeRange?.label !== "Date Range",
                  }),
                ]}
                htmlFor="dateFromID"
              >
                <i className="pi pi-calendar"></i>
              </label>
            </div>
          </div>
          {/* Text Alert When From date Invalid */}
          {isFromDateInvalid && (
            <div className="text-center">
              <small>Date Invalid</small>
            </div>
          )}
        </div>
        {/* To Date Wrapper */}
        <div
          className={[
            classNames({
              "date-picker-wrapper": true,
              "error-to-date-background": isToDateOlder || isToDateInvalid,
            }),
          ]}
        >
          <div className=" flex justify-content-center align-items-center gap-2">
            <label className="text-l">To : </label>
            <Dropdown
              value={dayOfToDate}
              onChange={(e) => setDayOfToDate(e.value)}
              options={days}
              optionLabel="name"
              placeholder="Day"
              className="w-full md:w-6rem"
              disabled={
                selectedTypeRange?.label !== "Date Range" || fromDate == null
              }
            />
            <Dropdown
              value={monthOfToDate}
              onChange={(e) => setMonthOfToDate(e.value)}
              options={months}
              optionLabel="name"
              placeholder="Month"
              className="w-full md:w-7rem"
              disabled={
                selectedTypeRange?.label !== "Date Range" || fromDate == null
              }
            />
            <div className="year-dropdown-calendar-btn-wrapper">
              <Dropdown
                value={yearOfToDate}
                onChange={(e) => setYearOfToDate(e.value)}
                options={years}
                optionLabel="name"
                placeholder="Year"
                className="w-full md:w-6rem"
                disabled={
                  selectedTypeRange?.label !== "Date Range" || fromDate == null
                }
              />
              <Calendar
                dateFormat="dd/mm/yy"
                value={toDate}
                onChange={(e) => {
                  // setToDate(e.value);
                  updateDate(
                    e.value,
                    setDayOfToDate,
                    setMonthOfToDate,
                    setYearOfToDate
                  );
                }}
                disabled={
                  selectedTypeRange?.label !== "Date Range" || fromDate == null
                }
                className={[
                  "from-calendar",
                  classNames({ "p-invalid": isInvalid }),
                ]}
                inputId="dateToID"
              />
              <label
                className={[
                  classNames({
                    "calendar-end-of-dropdown-btn": true,
                    disabled:
                      selectedTypeRange?.label !== "Date Range" ||
                      fromDate == null,
                  }),
                ]}
                htmlFor="dateToID"
              >
                <i className="pi pi-calendar"></i>
              </label>
            </div>
          </div>

          {/* Text Alert When To date older than From date */}
          {isToDateOlder && (
            <div className="text-center">
              <small>To Date must be equal to or later than From Date</small>
            </div>
          )}

          {/* Text Alert When To date Invalid */}
          {isToDateInvalid && (
            <div className="text-center">
              <small>Date Invalid</small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
