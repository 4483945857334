import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "primereact/resources/themes/mira/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Login } from "./pages/login/Login";
import { NavBar } from "./components/navBar/NavBar";
import { ListReport } from "./pages/list/ListReport";
import { ManualReport } from "./pages/manual/ManualReport";
import { Template } from "./pages/template/Template";
import { Log } from "./pages/log/Log";
import { useContext } from "react";
import { AuthContext } from "./assets/service/auth/AuthContext";
import { Navigate } from "react-router-dom";

function App() {
  const { token } = useContext(AuthContext);

  if (token === undefined) {
    return null; // or loading indicator/spinner/etc
  }

  return (
    <div className="wrapper">
      {!token && (
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Router>
      )}

      {token && (
        <Router>
          <NavBar>
            <Routes>
              <Route path="/" element={<ListReport />} />
              <Route path="/manual" element={<ManualReport />} />
              <Route path="/template" element={<Template />} />
              <Route path="/log" element={<Log />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </NavBar>
        </Router>
      )}
    </div>
  );
}

export default App;
