import axios from "axios";

export const QueryDataService = {
  // Get Preview Trend
  getPreviewTrends(resolve, reject, ids) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/manual-report/preview-trend?idOfLevel3=${ids}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  },

  getPreviewTrendData(ids) {
    return new Promise((resolve, reject) => {
      this.getPreviewTrends(resolve, reject, ids);
    });
  },

  // Post Query Report
  postQueryReport: async (
    reportName,
    reportType,
    selectedDateRange,
    selectedRollUp,
    selectedRollUpCal,
    ids
  ) => {
    const { token, userId } =
      JSON.parse(localStorage.getItem("userData")) || {};
    console.log(userId);
    try {
      const payload = {
        data: {
          reportName: reportName,
          reportType: reportType,
          user: userId ? userId.username : "",
          startDate: selectedDateRange.fromDate,
          endDate: selectedDateRange.toDate,
          rollup: selectedRollUp,
          type: selectedRollUpCal,
          idOfLevel3: ids,
        },
      };

      const headers = {
        Authorization: "Bearer " + token,
      };
      console.log(payload);
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/api/manual-report/query-report",
        payload,
        { headers }
      );

      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      throw error;
    }
  },
};
