import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { QueryListBoxTable } from "../../components/query-list-box/QueryListBoxTable";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { FullDateRange } from "../../../src/components/table-header/filter/date-range/FullDateRange";

export const EditTemplate = ({
  queryDatas,
  tmpTemplateWhenEdit,
  setTmpTemplateWhenEdit,
  rawQueryData,
  onQueryDataChange,
  selectedQueryDatas,
  exportQueryDatas,
  handleSelectionChange,
  validationState,
  actionBodyTemplate,
  setVisiblePreviewTrend,
  onBackEditTempalte,
  saveEditTemplate,
  handleDateRangeChange,
  selectedRollUpCal,
  selectedRollUp,
  handleRollUpChange,
}) => {
  return (
    <div className={`card-template flex gap-3 flex-column w-full`}>
      <div className="card-edit-text">
        <span>Edit mode</span>
      </div>
      <div>
        <Inplace className="template-name" closable active={true}>
          <InplaceDisplay>
            {tmpTemplateWhenEdit?.attributes.name || "Click to Edit Name"}
          </InplaceDisplay>
          <InplaceContent>
            <InputText
              value={tmpTemplateWhenEdit?.attributes.name}
              onChange={(e) =>
                setTmpTemplateWhenEdit((prev) => ({
                  ...prev,
                  attributes: { ...prev.attributes, name: e.target.value },
                }))
              }
              autoFocus
            />
          </InplaceContent>
        </Inplace>

        <Inplace className="template-description" closable active={true}>
          <InplaceDisplay>
            {tmpTemplateWhenEdit?.attributes.description ||
              "Click to Edit Description"}
          </InplaceDisplay>
          <InplaceContent>
            <InputTextarea
              value={tmpTemplateWhenEdit?.attributes.description}
              onChange={(e) =>
                setTmpTemplateWhenEdit((prev) => ({
                  ...prev,
                  attributes: {
                    ...prev.attributes,
                    description: e.target.value,
                  },
                }))
              }
              rows={1}
            />
          </InplaceContent>
        </Inplace>
      </div>

      {/* Date Range , AVG MIN MAX */}
      <div className="manual-box-header flex justify-content-between align-items-center">
        <FullDateRange
          onDateRangeChange={handleDateRangeChange}
          isDateRangeInvalid={validationState.isDateRangeInvalid}
          isRollupInvalid={validationState.isRollupInvalid}
          isRollupCalInvalid={
            selectedRollUpCal === null && selectedRollUp !== "None"
          }
          onRollUpChange={handleRollUpChange}
          selectedTemplateTimeRange={tmpTemplateWhenEdit.attributes}
        ></FullDateRange>
      </div>
      <div className="flex gap-3">
        <QueryListBoxTable
          selectedQueryDatasLength={selectedQueryDatas?.length}
          queryDatas={queryDatas}
          selectedQueryDatas={rawQueryData}
          onQueryDataChange={onQueryDataChange}
          maxHeight={"530px"}
        ></QueryListBoxTable>
        <div className="manual-box card w-full flex flex-column gap-3">
          {/* Query Data table */}
          <div className="manual-data-table-container">
            <DataTable
              className="manual-data-table"
              value={selectedQueryDatas}
              selection={exportQueryDatas}
              onSelectionChange={handleSelectionChange}
              dataKey="key"
              tableStyle={{ minWidth: "50rem" }}
              emptyMessage="Waiting for select query data"
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
              // Scroll
              scrollable
              scrollHeight="420px"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                className={` ${
                  validationState.isTableInvalid ? "invalid" : ""
                }`}
                headerClassName={` ${
                  validationState.isTableInvalid ? "invalid" : ""
                }`}
              ></Column>
              <Column
                header="No."
                headerStyle={{ width: "3rem" }}
                body={(data, options) => options.rowIndex + 1}
              ></Column>
              <Column field="data" header="Data name"></Column>
              <Column
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "5rem" }}
              ></Column>
            </DataTable>
          </div>

          {/* BTN preveie trend */}

          <Button
            tooltip={
              exportQueryDatas?.length < 1
                ? "Please select query data at least 1 item "
                : ""
            }
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              showOnDisabled: true,
            }}
            onClick={() => setVisiblePreviewTrend(true)}
            label="Preview trend"
            severity="info"
            className="w-full"
            disabled={exportQueryDatas?.length < 1}
          />

          <div className="query-report-container flex flex-auto justify-content-end align-items-end gap-3">
            <Button
              label="Back"
              onClick={onBackEditTempalte}
              severity="Secondary"
              outlined
              size="large"
            />
            <Button
              severity="warning"
              size="large"
              label="Edit Template"
              disabled={
                tmpTemplateWhenEdit?.templateName === "" ||
                tmpTemplateWhenEdit?.description === "" ||
                exportQueryDatas?.length <= 0
              }
              onClick={() => saveEditTemplate(tmpTemplateWhenEdit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
