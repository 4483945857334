export const formatDateYMD = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateDMY = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const getAggregationType = (cond) => {
  switch (cond) {
    case "AVG":
    case "Average":
      return "Average";
    case "MIN":
    case "Min":
      return "Minimum";
    case "MAX":
    case "Max":
      return "Maximum";
    case "SUM":
    case "Sum":
      return "Summary";

    default:
      break;
  }
};

export const findNodeByKey = (data, targetKey) => {
  for (let i = 0; i < data?.length; i++) {
    const node = data[i];

    if (node.key === targetKey) {
      return node;
    }

    // If the node has children, recursively search in children
    if (node.children && node.children.length > 0) {
      const childResult = findNodeByKey(node.children, targetKey);

      if (childResult) {
        return childResult;
      }
    }
  }

  return null;
};
