import "./ListReport.scss";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ListService } from "../../assets/service/ListService";
import { FilterTableHeader } from "../../components/table-header/filter/FilterTableHeader";
import { Badge } from "primereact/badge";
import { Paginator } from "primereact/paginator";
import { DateService } from "../../assets/service/DateService";

export const ListReport = ({}) => {
  let emptyReport = {
    id: null,
    name: "",
    reportName: "",
    price: 0,
    quantity: 0,
    rating: 0,
  };

  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState(null);
  const [deleteReportDialog, setDeleteReportDialog] = useState(false);
  const [deleteReportsDialog, setDeleteReportsDialog] = useState(false);
  const [report, setReport] = useState(emptyReport);
  const [selectedReports, setSelectedReports] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [dateRange, setDateRange] = useState();

  const [totalReports, setTotalReports] = useState();
  const [rows, setRows] = useState(5);
  let rowsPerPageOptions = [5, 10, 25];
  const [first, setFirst] = useState(0);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    fetchListData({ page: 0, rows: rows, first: 0 });
  }, []);

  function transformData(inputData) {
    return inputData.map((item) => ({
      id: item.id,
      reportType: item.attributes.reportType || null,
      reportName: item.attributes.reportName,
      user: item.attributes.user,
      date:
        new Date(item.attributes.createdAt).toISOString().split("T")[0] +
        "T00:01:00.000Z",
      dateRange:
        DateService.changeDateStringToDateFormal(item.attributes.startDate) +
        " to " +
        DateService.changeDateStringToDateFormal(item.attributes.endDate),
    }));
  }

  const fetchListData = async (event) => {
    try {
      setLoading(true);

      const data = await ListService.getReports(event.page + 1, event.rows);
      console.log(data);
      setReports(getReports(transformData(data.data)));
      setTotalReports(data.meta.pagination.total);
      setRows(event.rows);
      setFirst(event.first);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchListDataWithTimeRange = async (event, dateRange) => {
    try {
      setLoading(true);
      const data = await ListService.getReport(
        event.page + 1,
        event.rows,
        new Date(dateRange.fromDate).toISOString().split("T")[0] +
          "T00:01:00.000Z",
        new Date(dateRange.toDate).toISOString().split("T")[0] +
          "T23:59:00.000Z"
      );
      console.log(data);
      setReports((prev) => getReports(transformData(data.data)));
      setTotalReports((prev) => data.meta.pagination.total);
      setRows(event.rows);
      setFirst(event.first);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getReports = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);
      console.log(d);
      console.log(new Date(d.date));
      return d;
    });
  };

  const hideDeleteProductDialog = () => {
    setDeleteReportDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteReportsDialog(false);
  };

  const confirmDeleteReport = (report) => {
    setReport(report);
    setDeleteReportDialog(true);
  };

  const confirmDownloadReport = (report) => {};

  const deleteProduct = () => {
    ListService.postDelReports(report.id)
      .then(() => {
        let _products = reports.filter((val) => val.id !== report.id);
        setReports(_products);
        setDeleteReportDialog(false);
        setReport(emptyReport);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Report Deleted",
          life: 3000,
        });
      })
      .catch((error) => {
        console.error("Error deleting report:", error);
      });
  };

  const confirmDeleteSelected = () => {
    setDeleteReportsDialog(true);
  };

  const deleteSelectedProducts = () => {
    ListService.postDelReports(selectedReports.map((report) => report.id))
      .then(() => {
        let _products = reports.filter((val) => !selectedReports.includes(val));

        setReports(_products);
        setDeleteReportsDialog(false);
        setSelectedReports(null);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Reports Deleted",
          life: 3000,
        });
      })
      .catch((error) => {
        console.error("Error deleting report:", error);
      });
  };

  const download = () => {
    // dt.current.exportCSV();
    console.log(selectedReports);
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedReports || !selectedReports.length}
        />

        <Button
          label="Download"
          icon="pi pi-download"
          className="p-button-help"
          onClick={download}
          disabled={!selectedReports || !selectedReports.length}
        />
      </div>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex gap-2">
        <div className="flex align-items-center ">
          <Badge value="M" severity="success" className="mr-1"></Badge>: Manual
        </div>
        <div className="flex align-items-center ">
          <Badge value="T" severity="danger" className="mr-1"></Badge>: Template
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="mr-2"
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteReport(rowData)}
        />
        <Button
          icon="pi pi-download"
          rounded
          outlined
          severity="help"
          onClick={() => confirmDownloadReport(rowData)}
        />
      </React.Fragment>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return DateService.changeDateStringToDateFormal(rowData.date);
  };

  const typeBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        <Badge
          value={rowData.reportType === null ? "M" : "T"}
          severity={rowData.reportType === null ? "success" : "danger"}
        ></Badge>
        <span className="pl-3">{rowData.reportType}</span>
      </div>
    );
  };

  const handleDateRangeChange = (dateRange) => {
    if (dateRange.fromDate !== null && dateRange.toDate !== null) {
      setDateRange((prev) => dateRange);
      fetchListDataWithTimeRange({ page: 0, rows: rows, first: 0 }, dateRange);
    }
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h2 className="m-0">List Report</h2>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const handlePageChange = (event) => {
    if (dateRange) {
      if (dateRange?.fromDate !== null && dateRange?.toDate !== null)
        fetchListDataWithTimeRange(event, dateRange);
    } else fetchListData(event);
  };

  return (
    // <NavBar>
    <div className="list-report-container flex flex-column gap-3">
      <FilterTableHeader
        handleDateRangeChange={handleDateRangeChange}
      ></FilterTableHeader>
      <div>
        <Toast ref={toast} />
        <div className="card">
          <DataTable
            loading={loading}
            className="list-data-table"
            ref={dt}
            value={reports}
            selection={selectedReports}
            onSelectionChange={(e) => setSelectedReports(e.value)}
            dataKey="id"
            globalFilter={globalFilter}
            header={header}
            removableSort
            // Scroll
            scrollable
            scrollHeight="420px"
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column
              header="No."
              headerStyle={{ width: "3rem" }}
              body={(data, options) => first + options.rowIndex + 1}
            ></Column>
            <Column
              field="reportName"
              header="Name"
              sortable
              style={{ minWidth: "10rem" }}
            ></Column>
            <Column
              field="reportType"
              header="Type"
              sortable
              body={typeBodyTemplate}
              style={{ minWidth: "16rem" }}
            ></Column>
            <Column
              field="user"
              header="User"
              sortable
              style={{ minWidth: "7rem" }}
            ></Column>
            <Column
              field="date"
              header="Date"
              sortable
              dataType="date"
              body={dateBodyTemplate}
              style={{ minWidth: "7rem" }}
            ></Column>
            <Column
              field="dateRange"
              header="Time Range"
              sortable
              style={{ minWidth: "7rem" }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: "5rem" }}
            ></Column>
          </DataTable>
          <Paginator
            first={first}
            totalRecords={totalReports}
            rows={rows}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={handlePageChange}
            template={{
              layout:
                "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            }}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reports"
          />
          <Toolbar
            className="mb-4"
            end={rightToolbarTemplate}
            start={leftToolbarTemplate}
          ></Toolbar>
        </div>

        <Dialog
          visible={deleteReportDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteProductDialogFooter}
          onHide={hideDeleteProductDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {report && (
              <span>
                Are you sure you want to delete <b>{report.name}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteReportsDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {report && (
              <span>Are you sure you want to delete the selected reports?</span>
            )}
          </div>
        </Dialog>
      </div>
    </div>
    // </NavBar>
  );
};
