import React, { useState, useRef, useEffect } from "react";
import { TemplateListBoxTable } from "../../components/template-list-box/TemplateListBoxTable";
import "./Template.scss";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { TemplateService } from "../../assets/service/TemplateService";
import { PreviewTrendSideBar } from "../../components/sidebar/preview-trend/PreviewTrend";
import { QueryReportSideBar } from "../../components/sidebar/query-report/QueryReport";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { NavBar } from "../../components/navBar/NavBar";
import { QueryData } from "../../assets/service/QueryDataService";
import { getChildList } from "../../assets/utils";
import { OldTemplate } from "./OldTemplate";
import { EditTemplate } from "./EditTemplate";
import { NewTemplate } from "./NewTempalte";
import {
  formatDateDMY,
  getAggregationType,
} from "../../components/sidebar/query-report/utils";

export const Template = ({}) => {
  const toast = useRef(null);

  //Init template
  let emptyTemplate = {
    templateName: "",
    description: "",
  };

  // Tempalte Data
  const [template, setTemplate] = useState(emptyTemplate);
  // Selected Tempalte Data Name
  const [selectedtemplate, setSelectedtemplate] = useState(null);
  // All Query Data
  const [queryDatas, setQueryDatas] = useState(null);
  useEffect(() => {
    console.log("TEMPLATE REPORT USEEFFECT");
    QueryData.getQueryDataList().then((data) => setQueryDatas(data));
  }, []);

  // Selected query data from table left hand
  const [selectedQueryDatas, setSelectedQueryDatas] = useState([]);
  // Selected export query data from table
  const [exportQueryDatas, setExportQueryDatas] = useState([]);

  // Report Name
  const [reportName, setReportName] = useState("");

  // Validate State
  const [validationState, setValidationState] = useState({
    isDateRangeInvalid: false,
    isRollupInvalid: false,
    isRollupCalInvalid: false,
    isReportNameInvalid: false,
    isTableInvalid: false,
  });

  // Creat Template As Copy Dialog
  const [createTemplateAsCopyDialog, setCreateTemplateAsCopyDialog] =
    useState(false);
  // Bool Form got interact => template dialog form
  const [submittedCreateTemplateAsCopy, setSubmittedCreateTemplateAsCopy] =
    useState(false);

  // Bool Form got interact => name time range  datawant to export
  const [formInteracted, setFormInteracted] = useState(false);

  // Date Range
  const [selectedDateRange, setSelectedDateRange] = useState({
    fromDate: null,
    toDate: null,
  });
  // Rollup
  const [selectedRollUp, setSelectedRollUp] = useState(null);
  // RollupCal
  const [selectedRollUpCal, setSelectedRollUpCal] = useState();

  // bool Visible Sidebar (dialog)
  const [visiblePreviewTrend, setVisiblePreviewTrend] = useState(false);
  const [visibleQueryReport, setVisibleQueryReport] = useState(false);

  const [isClickCreateNewTemplate, setIsClickCreateNewTemplate] =
    useState(false);
  const [isClickEditTemplate, setIsClickEditTemplate] = useState(false);
  const [isValidCreatTemplate, setisValidCreatTemplate] = useState(false);

  // Back up template data
  const [tmpTemplateWhenEdit, setTmpTemplateWhenEdit] = useState();

  // Collect Query Data all parent , child
  const [rawQueryData, setRawQueryData] = useState();

  useEffect(() => {
    if (formInteracted) {
      setValidationState((prev) => ({
        ...prev,
        isDateRangeInvalid:
          selectedDateRange?.fromDate === null ||
          selectedDateRange?.toDate === null,
        isRollupInvalid: selectedRollUp === null,
        isRollupCalInvalid: selectedRollUpCal === null,
        isReportNameInvalid: reportName === "",
        isTableInvalid: exportQueryDatas.length === 0,
      }));
    }
  }, [
    formInteracted,
    selectedDateRange,
    selectedRollUp,
    reportName,
    exportQueryDatas,
  ]);

  const onTemplateListChange = (template) => {
    console.log("onTemplateListChange");
    TemplateService.getTemplate(template?.id).then((templateData) => {
      console.log(" GET  1");

      if (templateData) {
        setReportName(templateData.attributes.name);
        setIsClickCreateNewTemplate(false);
        setSelectedtemplate(templateData);
        onQueryDataChange(templateData.attributes.queryData);
        setExportQueryDatas(templateData.attributes.exportQueryData);
      } else {
        console.log(`Template with name ${template} not found.`);
        setSelectedtemplate(null);
      }
    });
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedRollUp(null);
    setSelectedRollUpCal(null);
    setSelectedDateRange(dateRange);
  };

  const handleRollUpChange = (rollup) => {
    if (rollup?.selectedChartDataRollup !== undefined)
      setSelectedRollUp(rollup?.selectedChartDataRollup);
    if (rollup?.selectedChartDataRollupCal !== undefined)
      setSelectedRollUpCal(rollup?.selectedChartDataRollupCal);
  };

  const handleSelectionChange = (e) => {
    console.log(e);
    setExportQueryDatas(e.value);
    // setVisiblePreviewTrend(true);
  };

  const confirmDeleteList = (list) => {
    let key = list.key;
    const updatedQueryDatas = selectedQueryDatas.filter(
      (item) => item.key !== key
    );
    const updatedWantToExportDatas = exportQueryDatas.filter(
      (item) => item.key !== key
    );

    // handleSelectionChange({ value: updatedQueryDatas });
    handleSelectionChange({ value: updatedWantToExportDatas });
    setSelectedQueryDatas(updatedQueryDatas);

    const updatedRawQueryData = { ...rawQueryData };
    // updatedRawQueryData[key] = { checked: false, partialChecked: false };
    // if (updatedRawQueryData[key].checked === false) {
    const keys = key.split("-").map(Number);
    // keys.pop();
    let currentKey = "";
    for (const k of keys) {
      currentKey += (currentKey === "" ? "" : "-") + k;
      // if (updatedRawQueryData[currentKey]) {
      // Update existing object values
      const childrenKeys = Object.keys(updatedRawQueryData).filter(
        (childKey) => {
          return childKey.startsWith(currentKey + "-") && childKey !== key;
        }
      );
      if (childrenKeys.length > 0) {
        updatedRawQueryData[currentKey].checked = false;
        updatedRawQueryData[currentKey].partialChecked = true;

        if (
          childrenKeys.reduce(
            (acc, item) => acc + (item.split("-").length - 1),
            0
          ) === 1
        ) {
          delete updatedRawQueryData[currentKey];
        }
      } else {
        delete updatedRawQueryData[currentKey];
      }
    }
    // }

    console.log(updatedRawQueryData);
    setRawQueryData(updatedRawQueryData);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="mr-2"
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteList(rowData)}
        />
      </React.Fragment>
    );
  };

  const openCreateTemplateAsCopy = () => {
    setTemplate(emptyTemplate);
    setSubmittedCreateTemplateAsCopy(false);
    setCreateTemplateAsCopyDialog(true);
  };

  const handleQueryReportClick = () => {
    setFormInteracted(true);

    setValidationState((prev) => ({
      ...prev,
      isDateRangeInvalid:
        selectedDateRange?.fromDate === null ||
        selectedDateRange?.toDate === null,
      isRollupInvalid: selectedRollUp === null,
      isRollupCalInvalid: selectedRollUpCal === null,
      isReportNameInvalid: reportName === "",
      isTableInvalid: exportQueryDatas.length === 0,
    }));

    const {
      isDateRangeInvalid,
      isRollupInvalid,
      isRollupCalInvalid,
      isReportNameInvalid,
      isTableInvalid,
    } = validationState;

    if (
      isDateRangeInvalid ||
      isRollupInvalid ||
      isReportNameInvalid ||
      isTableInvalid ||
      selectedDateRange?.fromDate === null ||
      selectedDateRange?.toDate === null ||
      selectedRollUp === null ||
      (selectedRollUpCal === null && selectedRollUp !== "None") ||
      reportName === "" ||
      exportQueryDatas.length === 0
    ) {
      return;
    }

    setVisibleQueryReport(true);
    console.log("Query report clicked");
  };

  const onQueryDataChange = (data) => {
    setSelectedQueryDatas(getChildList(data, queryDatas));
    setRawQueryData(data);
  };

  const hideCreateTemplateAsCopy = () => {
    setSubmittedCreateTemplateAsCopy(false);
    setCreateTemplateAsCopyDialog(false);
  };

  const saveCreateTemplateAsCopy = () => {
    setSubmittedCreateTemplateAsCopy(true);
    if (template.templateName.trim() && template.description.trim()) {
      console.log(
        template,
        rawQueryData,
        selectedDateRange,
        selectedRollUp,
        selectedRollUpCal,
        exportQueryDatas
      );
      TemplateService.postCreateTemplate(
        template,
        rawQueryData,
        selectedDateRange,
        selectedRollUp,
        selectedRollUpCal,
        exportQueryDatas
      )
        .then((result) => {
          console.log("Template created as copy successfully:", result);

          setCreateTemplateAsCopyDialog(false);
          setTemplate(emptyTemplate);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "New Template Created",
            life: 3000,
          });
        })
        .catch((error) => {
          console.error("Error creating as copy template:", error);
        });
    }
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _template = { ...template };

    _template[`${name}`] = val;

    setTemplate(_template);
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideCreateTemplateAsCopy}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={saveCreateTemplateAsCopy}
      />
    </React.Fragment>
  );

  const handleClickCreateNewTemplate = () => {
    setIsClickEditTemplate(false);
    setSelectedtemplate(emptyTemplate);
    setExportQueryDatas([]);
    setSelectedQueryDatas([]);
    setRawQueryData([]);
    setReportName("");
    setIsClickCreateNewTemplate(true);
  };

  const savecreateTemplateFromNewTemplate = () => {
    setisValidCreatTemplate(true);
    handleQueryReportClick();

    const isTemplateValid =
      selectedtemplate.templateName.trim() &&
      selectedtemplate.description.trim() &&
      exportQueryDatas?.length > 0 &&
      selectedDateRange?.fromDate !== null &&
      selectedDateRange?.toDate !== null &&
      selectedRollUp !== null;

    if (
      isTemplateValid &&
      (selectedRollUp === "None" || selectedRollUpCal !== null)
    ) {
      console.log(
        selectedtemplate,
        rawQueryData,
        selectedDateRange,
        selectedRollUp,
        selectedRollUpCal,
        exportQueryDatas
      );

      TemplateService.postCreateTemplate(
        selectedtemplate,
        rawQueryData,
        selectedDateRange,
        selectedRollUp,
        selectedRollUpCal,
        exportQueryDatas
      )
        .then((result) => {
          console.log("Template created successfully:", result);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Template Created",
            life: 3000,
          });
          setSelectedtemplate(null);
          setIsClickCreateNewTemplate(false);
          setisValidCreatTemplate(false);
        })
        .catch((error) => {
          console.error("Error creating template:", error);
        });
    }
  };

  const saveEditTemplate = (datas) => {
    confirmDialog({
      message: "Are you sure you want to edit this template?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        console.log("saveEditTemplate");
        let tmpData = {
          templateName: datas.attributes.name,
          description: datas.attributes.description,
        };
        console.log(rawQueryData);
        TemplateService.putEditTemplate(
          datas.id,
          tmpData,
          rawQueryData,
          selectedDateRange,
          selectedRollUp,
          selectedRollUpCal,
          exportQueryDatas
        )
          .then((result) => {
            console.log("Template created successfully:", result);
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Template Edited",
              life: 3000,
            });
            setSelectedtemplate(null);
            setIsClickEditTemplate(false);
          })
          .catch((error) => {
            console.error("Error creating template:", error);
          });
      },
    });
  };

  const onBackEditTempalte = () => {
    setIsClickEditTemplate(false);
  };

  const handleClickEditTemplate = (datas) => {
    setTmpTemplateWhenEdit(datas);
    setIsClickEditTemplate(true);
  };

  return (
    <div className="template-report-container">
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="template-list-box-table-container flex gap-3">
        <div className="flex flex-column">
          <TemplateListBoxTable
            onTemplateListChange={onTemplateListChange}
            isClickEditTemplate={isClickEditTemplate}
            setIsClickEditTemplate={setIsClickEditTemplate}
            isClickCreateNewTemplate={isClickCreateNewTemplate}
            tmpSelected={selectedtemplate}
            submittedCreateTemplateAsCopy={submittedCreateTemplateAsCopy}
          ></TemplateListBoxTable>
          <div className="pt-2">
            <Button
              label="Create New Template"
              className="w-full"
              outlined
              onClick={handleClickCreateNewTemplate}
            />
          </div>
        </div>

        {/* Clicking Old Template */}
        {isClickCreateNewTemplate === false &&
          isClickEditTemplate === false &&
          selectedtemplate !== null && (
            <OldTemplate
              queryDatas={queryDatas}
              selectedtemplate={selectedtemplate}
              rawQueryData={rawQueryData}
              onQueryDataChange={onQueryDataChange}
              reportName={reportName}
              setReportName={setReportName}
              validationState={validationState}
              handleDateRangeChange={handleDateRangeChange}
              selectedRollUpCal={selectedRollUpCal}
              selectedRollUp={selectedRollUp}
              handleRollUpChange={handleRollUpChange}
              selectedQueryDatas={selectedQueryDatas}
              exportQueryDatas={exportQueryDatas}
              handleSelectionChange={handleSelectionChange}
              actionBodyTemplate={actionBodyTemplate}
              setVisiblePreviewTrend={setVisiblePreviewTrend}
              openCreateTemplateAsCopy={openCreateTemplateAsCopy}
              handleClickEditTemplate={handleClickEditTemplate}
              handleQueryReportClick={handleQueryReportClick}
            ></OldTemplate>
          )}
        {/* Clicking Edit Template */}
        {isClickCreateNewTemplate === false && isClickEditTemplate && (
          <EditTemplate
            queryDatas={queryDatas}
            tmpTemplateWhenEdit={tmpTemplateWhenEdit}
            setTmpTemplateWhenEdit={setTmpTemplateWhenEdit}
            rawQueryData={rawQueryData}
            onQueryDataChange={onQueryDataChange}
            selectedQueryDatas={selectedQueryDatas}
            exportQueryDatas={exportQueryDatas}
            handleSelectionChange={handleSelectionChange}
            validationState={validationState}
            actionBodyTemplate={actionBodyTemplate}
            setVisiblePreviewTrend={setVisiblePreviewTrend}
            onBackEditTempalte={onBackEditTempalte}
            saveEditTemplate={saveEditTemplate}
            handleDateRangeChange={handleDateRangeChange}
            selectedRollUpCal={selectedRollUpCal}
            selectedRollUp={selectedRollUp}
            handleRollUpChange={handleRollUpChange}
          ></EditTemplate>
        )}
        {/* Clicking Create New Template */}
        {isClickCreateNewTemplate && (
          <NewTemplate
            queryDatas={queryDatas}
            isValidCreatTemplate={isValidCreatTemplate}
            selectedtemplate={selectedtemplate}
            setSelectedtemplate={setSelectedtemplate}
            rawQueryData={rawQueryData}
            onQueryDataChange={onQueryDataChange}
            selectedQueryDatas={selectedQueryDatas}
            exportQueryDatas={exportQueryDatas}
            handleSelectionChange={handleSelectionChange}
            actionBodyTemplate={actionBodyTemplate}
            setVisiblePreviewTrend={setVisiblePreviewTrend}
            savecreateTemplateFromNewTemplate={
              savecreateTemplateFromNewTemplate
            }
            handleDateRangeChange={handleDateRangeChange}
            validationState={validationState}
            selectedRollUpCal={selectedRollUpCal}
            selectedRollUp={selectedRollUp}
            handleRollUpChange={handleRollUpChange}
          ></NewTemplate>
        )}
      </div>

      {/* Dialog Creat Template */}
      <Dialog
        visible={createTemplateAsCopyDialog}
        style={{ width: "40rem" }}
        breakpoints={{ "1000px": "75vw", "641px": "90vw" }}
        header="New Template Detail"
        modal
        className="p-fluid create-template-dialog"
        footer={productDialogFooter}
        onHide={hideCreateTemplateAsCopy}
      >
        {/* Template Name */}
        <div className="field">
          <label htmlFor="templateName" className="font-bold">
            Template Name
          </label>
          <InputText
            id="templateName"
            value={template.templateName}
            onChange={(e) => onInputChange(e, "templateName")}
            required
            autoFocus
            className={classNames({
              "p-invalid":
                submittedCreateTemplateAsCopy && !template.templateName,
            })}
          />
          {submittedCreateTemplateAsCopy && !template.templateName && (
            <small className="p-error">Template Name is required.</small>
          )}
        </div>

        {/* Description*/}
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <InputTextarea
            id="description"
            value={template.description}
            onChange={(e) => onInputChange(e, "description")}
            required
            className={classNames({
              "p-invalid":
                submittedCreateTemplateAsCopy && !template.description,
            })}
            rows={2}
          />
          {submittedCreateTemplateAsCopy && !template.description && (
            <small className="p-error">Description is required.</small>
          )}
        </div>

        {/* wrapper of date info */}
        <div className="field dialog-box-create-template mb-5">
          <div className="header">
            <span className="text select-none">Time Range</span>
          </div>
          <div className="flex justify-content-center align-items-end gap-5 mb-3 text-xl">
            <div className="flex gap-4 flex-wrap justify-content-center">
              <div className="flex justify-content-center align-items-center gap-2">
                <label className="text-l">From : </label>
                <div>{formatDateDMY(selectedDateRange?.fromDate)}</div>
              </div>
              <div className="flex justify-content-center align-items-center gap-2">
                <label className="text-l">To : </label>
                <div>{formatDateDMY(selectedDateRange?.toDate)}</div>
              </div>
              <div className="flex justify-content-center align-items-center gap-2">
                <label className="text-l">Rollup : </label>
                <div>{selectedRollUp}</div>
              </div>
              {selectedRollUp !== "None" && (
                <div className="flex justify-content-center align-items-center gap-2">
                  <label className="text-l">Type : </label>
                  <div>{getAggregationType(selectedRollUpCal)}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Query Data */}
        <div className="field dialog-box-create-template">
          <div className="header">
            <span className="text">Query Data</span>
          </div>
          <div className="flex overflow-scroll flex-wrap justify-content-center align-items-start h-auto">
            {exportQueryDatas.map((item) => (
              <span className="query-data-dialog text-center" key={item.key}>
                {item.data}
              </span>
            ))}
          </div>
        </div>
      </Dialog>

      {/* Side bar Preview Trend */}
      <PreviewTrendSideBar
        visiblePreviewTrend={visiblePreviewTrend}
        setVisiblePreviewTrend={setVisiblePreviewTrend}
        exportQueryDatas={exportQueryDatas}
      ></PreviewTrendSideBar>

      {/* Side bar Query Report */}
      <QueryReportSideBar
        visibleQueryReport={visibleQueryReport}
        setVisibleQueryReport={setVisibleQueryReport}
        selectedDateRange={selectedDateRange}
        selectedRollUp={selectedRollUp}
        selectedRollUpCal={selectedRollUpCal}
        dataTypeExport={{ name: "AVG" }}
        reportName={reportName}
        exportQueryDatas={exportQueryDatas}
        templateName={selectedtemplate?.attributes.name}
      ></QueryReportSideBar>
    </div>
  );
};
