import axios from "axios";

export const QueryData = {
  getQueryDataLists(resolve, reject) {
    console.log("getQueryDatas");
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/level-1-groups?populate[0]=level_2_groups&populate[level_2_groups][populate][0]=listPoint&sort=id&pagination[page]=1&pagination[pageSize]=10",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        resolve(transformData(response.data.data));
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getQueryDataList() {
    return new Promise((resolve, reject) => {
      this.getQueryDataLists(resolve, reject);
    });
  },
};

const transformData = (input) => {
  return input.map((item) => {
    const transformGroup = (group) => {
      const transformedGroup = {
        key: group.attributes.key,
        label: group.attributes.label,
        data: group.attributes.data,
        icon: "pi pi-fw pi-cog", // Set your desired icon here
        children: [],
      };

      if (group.attributes.listPoint && group.attributes.listPoint.data) {
        transformedGroup.children = group.attributes.listPoint.data.map(
          (point) => ({
            id: point.id,
            key: point.attributes.key,
            label: point.attributes.label,
            data: point.attributes.data,
            icon: "pi pi-fw pi-file", // Set your desired icon here
          })
        );
      }

      if (
        group.attributes.level_2_groups &&
        group.attributes.level_2_groups.data
      ) {
        transformedGroup.children = transformedGroup.children.concat(
          group.attributes.level_2_groups.data.map(transformGroup)
        );
      }

      return transformedGroup;
    };

    return transformGroup(item);
  });
};
