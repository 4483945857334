import "./Log.scss";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { LogService } from "../../assets/service/LogService";
import { Toolbar } from "primereact/toolbar";
import { FilterTableHeader } from "../../components/table-header/filter/FilterTableHeader";
import { NavBar } from "../../components/navBar/NavBar";
import { DatabasStateService } from "../../assets/service/DatabaseStateService";
import { DateService } from "../../assets/service/DateService";
import { Paginator } from "primereact/paginator";
export const Log = ({}) => {
  const [loading, setLoading] = useState(true);
  const [loadingSQLText, setLoadingSQLText] = useState(true);
  const [logs, setLogs] = useState(null);
  // const [selectedReports, setSelectedReports] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [DB, setDB] = useState();
  const [dateRange, setDateRange] = useState();

  const [totalReports, setTotalReports] = useState();
  const [rows, setRows] = useState(5);
  let rowsPerPageOptions = [5, 10, 25];
  const [first, setFirst] = useState(0);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    fetchDatabaseState();
    fetchLogData({ page: 0, rows: rows, first: 0 });
    // LogService.getLogs().then((data) => setLogs(getLogs(data)));
    setLoading(false);
  }, []);

  const fetchDatabaseState = async () => {
    try {
      setLoadingSQLText(true);

      const data = await DatabasStateService.getDBState();
      setDB(data.attributes);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingSQLText(false);
    }
  };

  const fetchLogData = async (event) => {
    try {
      setLoading(true);

      const data = await LogService.getLogs(event.page + 1, event.rows);
      console.log(data);
      setLogs(getLogs(transformData(data.data)));
      setTotalReports(data.meta.pagination.total);
      setRows(event.rows);
      setFirst(event.first);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLogDataWithTimeRange = async (event, dateRange) => {
    try {
      setLoading(true);
      const data = await LogService.getLog(
        event.page + 1,
        event.rows,
        new Date(dateRange.fromDate).toISOString().split("T")[0] +
          "T00:01:00.098Z",
        new Date(dateRange.toDate).toISOString().split("T")[0] +
          "T23:59:00.098Z"
      );
      console.log(data);
      setLogs((prev) => getLogs(transformData(data.data)));
      setTotalReports((prev) => data.meta.pagination.total);
      setRows(event.rows);
      setFirst(event.first);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getLogs = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date).toLocaleDateString("en-US");
      console.log(d.date);
      return d;
    });
  };

  function transformData(inputData) {
    return inputData.map((item) => ({
      id: item.id,
      description: item.attributes.description,
      ip: item.attributes.ip,
      date:
        new Date(item.attributes.createdAt).toISOString().split("T")[0] +
        "T00:01:00.000Z",
      time: DateService.changeDateStringToTimeFormalISO(
        item.attributes.createdAt
      ),
    }));
  }

  const download = () => {
    // dt.current.exportCSV();
    // console.log(selectedReports);
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Export"
          icon="pi pi-file-export"
          className="p-button-help"
          onClick={download}
          // disabled={!selectedReports || !selectedReports.length}
        />
      </div>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return DateService.changeDateStringToDateFormal(rowData.date);
  };

  const handleDateRangeChange = (dateRange) => {
    if (dateRange.fromDate !== null && dateRange.toDate !== null) {
      setDateRange((prev) => dateRange);
      fetchLogDataWithTimeRange({ page: 0, rows: rows, first: 0 }, dateRange);
    }
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h2 className="m-0">SQL log</h2>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const getDBText = (selected) => {
    switch (selected) {
      case "db1":
        return DB.db1Name;
      case "db2":
        return DB.db2Name;

      default:
        return "ERROR";
    }
  };

  const handlePageChange = (event) => {
    if (dateRange) {
      if (dateRange?.fromDate !== null && dateRange?.toDate !== null)
        fetchLogDataWithTimeRange(event, dateRange);
    } else fetchLogData(event);
  };

  return (
    <div className="log-container  flex flex-column gap-3">
      <div className="card flex align-items-center gap-4">
        <div className="text-2xl">SQL Activated</div>
        <span className="currently-active text-2xl flex">
          {loadingSQLText ? (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          ) : (
            getDBText(DB?.dbSelected)
          )}
        </span>
      </div>
      <FilterTableHeader
        handleDateRangeChange={handleDateRangeChange}
      ></FilterTableHeader>
      <div>
        <Toast ref={toast} />
        <div className="card">
          <DataTable
            loading={loading}
            className="list-data-table"
            ref={dt}
            value={logs}
            // selection={selectedReports}
            // onSelectionChange={(e) => setSelectedReports(e.value)}
            dataKey="id"
            globalFilter={globalFilter}
            header={header}
            removableSort
            // Scroll
            scrollable
            scrollHeight="420px"
          >
            <Column
              header="No."
              headerStyle={{ width: "3rem" }}
              body={(data, options) => options.rowIndex + 1}
            ></Column>

            <Column
              field="date"
              header="Date"
              sortable
              dataType="date"
              body={dateBodyTemplate}
              style={{ minWidth: "7rem" }}
            ></Column>
            <Column
              field="time"
              header="Time"
              sortable
              style={{ minWidth: "10rem" }}
            ></Column>
            <Column
              field="description"
              header="Description"
              sortable
              style={{ minWidth: "10rem" }}
            ></Column>
            <Column
              field="ip"
              header="IP"
              sortable
              style={{ minWidth: "10rem" }}
            ></Column>
          </DataTable>
          <Paginator
            first={first}
            totalRecords={totalReports}
            rows={rows}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={handlePageChange}
            template={{
              layout:
                "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            }}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reports"
          />
          <Toolbar className="mb-4" end={rightToolbarTemplate}></Toolbar>
        </div>
      </div>
    </div>
  );
};
