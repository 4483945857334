import { createContext, useCallback, useState, useEffect } from "react";

export const AuthContext = createContext({
  token: null,
  userId: null,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

const AuthContextProvider = (props) => {
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const { userId, token } =
      JSON.parse(localStorage.getItem("userData")) || {};
    if (userId) setUserId(userId);
    if (token) setToken(token);

    if (userId && token) {
      loginUserHandler(userId, token);
    }
  }, []);

  useEffect(() => {
    if (token || userId) {
      localStorage.setItem("userData", JSON.stringify({ userId, token }));
    } else {
      localStorage.removeItem("userData");
    }
  }, [token, userId]);

  const loginUserHandler = useCallback((userId, token) => {
    setUserId(userId);
    setToken(token);
  }, []);

  const logoutUserHandler = useCallback(() => {
    setUserId(null);
    setToken(null);
  }, []);

  const initialValues = {
    userId: userId,
    token: token,
    isLoggedIn: !!token,
    login: loginUserHandler,
    logout: logoutUserHandler,
  };

  return (
    <AuthContext.Provider value={initialValues}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
