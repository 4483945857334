import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import Chart from "react-apexcharts";
import { QueryDataService } from "../../../assets/service/DataService";
import { DateService } from "../../../assets/service/DateService";

export const PreviewTrendSideBar = ({
  visiblePreviewTrend,
  setVisiblePreviewTrend,
  exportQueryDatas,
}) => {
  const [chart, setChart] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: [
        "#96D1F5",
        "#6A9DBF",
        "#4E6C83",
        "#8C9B6D",
        "#A77D6B",
        "#B2A3AC",
        "#6C7D8E",
      ],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Raw Data",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        title: {
          text: "Date",
        },
      },
      yaxis: {
        title: {
          text: "Value",
        },
      },
    },
  });

  useEffect(() => {
    if (visiblePreviewTrend === true && exportQueryDatas.length > 0) {
      console.log("exportQueryDatas");
      console.log(exportQueryDatas);
      fetchData();
    }
  }, [visiblePreviewTrend]);

  const fetchData = async () => {
    console.log("fechData");
    let ids = exportQueryDatas.map((data) => data.id).toString();
    try {
      QueryDataService.getPreviewTrendData(ids)
        .then((result) => {
          setChart((prevOptions) => ({
            ...prevOptions,
            series: result.series,
            options: {
              ...prevOptions.options,
              xaxis: {
                categories: result.categories.map(
                  (entry) =>
                    DateService.changeDateStringToDateFormal(entry) +
                    " " +
                    DateService.changeDateStringToTimeFormal(entry)
                ),
                ...prevOptions.options.xaxis,
              },
            },
          }));
        })
        .catch((error) => {
          console.error("Error Query Report:", error);
        });
    } catch (error) {
      console.error(`Error fetching data for  error`);
      return null;
    }
  };

  return (
    <Sidebar
      className="p-sidebar-lg"
      visible={visiblePreviewTrend}
      position="right"
      onHide={() => {
        setVisiblePreviewTrend(false);
      }}
    >
      <h2>Preview trend</h2>
      <Chart
        options={chart.options}
        series={chart.series}
        type="line"
        width="900"
      />
    </Sidebar>
  );
};
