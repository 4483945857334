import React, { useState, useEffect } from "react";
import { DateRange } from "./DateRange";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export const FullDateRange = ({
  onDateRangeChange,
  onRollUpChange,
  isDateRangeInvalid,
  isRollupInvalid,
  isRollupCalInvalid,
  selectedTemplateTimeRange,
}) => {
  const [selectedChartDataRollup, setSelectedChartDataRollup] = useState(null);
  const [selectedChartDataRollupCal, setSelectedChartDataRollupCal] =
    useState(null);
  // Date Range
  const [selectedDateRange, setSelectedDateRange] = useState({
    fromDate: null,
    toDate: null,
  });

  const chartDatasRollup = [
    "None",
    // "1 minutes",
    // "5 minutes",
    "15 minutes",
    "30 minutes",
    "Hour",
    "Day",
    "Week",
    // "Month",
    // "Year",
  ].map((label) => ({
    label,
  }));

  const chartDatasRollupCal = ["Average", "Min", "Max", "Sum"].map((label) => ({
    label,
  }));

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
    onDateRangeChange(dateRange);
  };

  useEffect(() => {
    // if (
    //   selectedDateRange?.fromDate === null &&
    //   selectedDateRange?.toDate === null
    // ) {
    console.log("selectedTemplateTimeRange");
    console.log(selectedTemplateTimeRange);
    if (selectedTemplateTimeRange) {
      setSelectedChartDataRollup({ label: selectedTemplateTimeRange.rollup });
      setSelectedChartDataRollupCal({
        label: selectedTemplateTimeRange.type,
      });
    } else {
      setSelectedChartDataRollup(null);
      setSelectedChartDataRollupCal(null);
    }

    // }
  }, [selectedDateRange]);

  useEffect(() => {
    // if (
    //   selectedDateRange?.fromDate !== null &&
    //   selectedDateRange?.toDate !== null
    // ) {
    console.log("ON CHANGE IN ROLL UP");
    console.log(selectedChartDataRollup?.label);
    console.log(selectedChartDataRollupCal?.label);
    const rollUp = {
      selectedChartDataRollup: selectedChartDataRollup?.label,
      selectedChartDataRollupCal: selectedChartDataRollupCal?.label,
    };
    onRollUpChange(rollUp);
    // }
  }, [selectedChartDataRollup, selectedChartDataRollupCal]);

  return (
    <div className="flex justify-content-between gap-1 flex-column">
      <DateRange
        onDateRangeChange={handleDateRangeChange}
        isInvalid={isDateRangeInvalid}
        selectedTemplateTimeRange={selectedTemplateTimeRange}
      ></DateRange>

      <div className="flex gap-5">
        <div style={{ padding: "5px 10px" }}>
          <label className="text-l">Rollup : </label>
          <Dropdown
            value={selectedChartDataRollup}
            onChange={(e) => setSelectedChartDataRollup(e.value)}
            options={chartDatasRollup}
            optionLabel="label"
            className={[
              "md:w-12rem",
              classNames({ "p-invalid": isRollupInvalid }),
            ]}
            disabled={
              selectedDateRange?.fromDate === null ||
              selectedDateRange?.toDate === null
            }
          />
        </div>
        <div>
          {selectedChartDataRollup?.label !== "None" && (
            <div style={{ padding: "5px 10px" }}>
              <label className="text-l">Type : </label>
              <Dropdown
                value={selectedChartDataRollupCal}
                onChange={(e) => setSelectedChartDataRollupCal(e.value)}
                options={chartDatasRollupCal}
                optionLabel="label"
                className={[
                  "md:w-12rem",
                  classNames({ "p-invalid": isRollupCalInvalid }),
                ]}
                disabled={
                  selectedDateRange?.fromDate === null ||
                  selectedDateRange?.toDate === null ||
                  selectedChartDataRollup === null
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
