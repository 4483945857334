import React, { useState, useEffect, useRef } from "react";
import "./ManualReport.scss";
import { QueryListBoxTable } from "../../components/query-list-box/QueryListBoxTable";
import { FullDateRange } from "../../../src/components/table-header/filter/date-range/FullDateRange";
import { PreviewTrendSideBar } from "../../components/sidebar/preview-trend/PreviewTrend";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { QueryReportSideBar } from "../../components/sidebar/query-report/QueryReport";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { NavBar } from "../../components/navBar/NavBar";
import { findNodeByKey } from "../../components/sidebar/query-report/utils";
import { QueryData } from "../../assets/service/QueryDataService";
import { TemplateService } from "../../assets/service/TemplateService";
import { getChildList } from "../../assets/utils";
import {
  formatDateDMY,
  getAggregationType,
} from "../../components/sidebar/query-report/utils";

export const ManualReport = ({}) => {
  const toast = useRef(null);

  //Init template
  let emptyTemplate = {
    templateName: "",
    description: "",
  };

  // Selected query data from table left hand
  const [selectedQueryDatas, setSelectedQueryDatas] = useState([]);
  // Selected export query data from table
  const [exportQueryDatas, setExportQueryDatas] = useState([]);

  // bool Visible Sidebar (dialog)
  const [visiblePreviewTrend, setVisiblePreviewTrend] = useState(false);
  const [visibleQueryReport, setVisibleQueryReport] = useState(false);

  // Report Name
  const [reportName, setReportName] = useState("");
  // Date Range
  const [selectedDateRange, setSelectedDateRange] = useState({
    fromDate: null,
    toDate: null,
  });
  // Rollup
  const [selectedRollUp, setSelectedRollUp] = useState(null);
  // RollupCal
  const [selectedRollUpCal, setSelectedRollUpCal] = useState();

  // Validate State
  const [validationState, setValidationState] = useState({
    isDateRangeInvalid: false,
    isRollupInvalid: false,
    isRollupCalInvalid: false,
    isReportNameInvalid: false,
    isTableInvalid: false,
  });

  // Bool Form got interact => name time range  datawant to export
  const [formInteracted, setFormInteracted] = useState(false);
  // Creat Template Dialog
  const [createTemplateDialog, setCreateTemplateDialog] = useState(false);
  // Bool Form got interact => template dialog form
  const [submittedCreateTemplate, setSubmittedCreateTemplate] = useState(false);

  // Tempalte Data
  const [template, setTemplate] = useState(emptyTemplate);
  // Collect Query Data all parent , child
  const [rawQueryData, setRawQueryData] = useState();
  // All Query Data
  const [queryDatas, setQueryDatas] = useState(null);

  useEffect(() => {
    console.log("MANUAL REPORT USEEFFECT");
    QueryData.getQueryDataList().then((data) => setQueryDatas(data));
  }, []);

  useEffect(() => {
    if (formInteracted) {
      setValidationState((prev) => ({
        ...prev,
        isDateRangeInvalid:
          selectedDateRange?.fromDate === null ||
          selectedDateRange?.toDate === null,
        isRollupInvalid: selectedRollUp === null,
        isRollupCalInvalid: selectedRollUpCal === null,
        isReportNameInvalid: reportName === "",
        isTableInvalid: exportQueryDatas.length === 0,
      }));
    }
  }, [
    formInteracted,
    selectedDateRange,
    selectedRollUp,
    reportName,
    exportQueryDatas,
  ]);

  const handleDateRangeChange = (dateRange) => {
    setSelectedRollUp(null);
    setSelectedRollUpCal(null);
    setSelectedDateRange(dateRange);
  };

  const handleRollUpChange = (rollup) => {
    if (rollup?.selectedChartDataRollup !== undefined)
      setSelectedRollUp(rollup?.selectedChartDataRollup);
    if (rollup?.selectedChartDataRollupCal !== undefined)
      setSelectedRollUpCal(rollup?.selectedChartDataRollupCal);
  };

  const handleQueryReportClick = () => {
    setFormInteracted(true);

    setValidationState((prev) => ({
      ...prev,
      isDateRangeInvalid:
        selectedDateRange?.fromDate === null ||
        selectedDateRange?.toDate === null,
      isRollupInvalid: selectedRollUp === null,
      isRollupCalInvalid: selectedRollUpCal === null,
      isReportNameInvalid: reportName === "",
      isTableInvalid: exportQueryDatas.length === 0,
    }));

    const {
      isDateRangeInvalid,
      isRollupInvalid,
      isRollupCalInvalid,
      isReportNameInvalid,
      isTableInvalid,
    } = validationState;

    if (
      isDateRangeInvalid ||
      isRollupInvalid ||
      isReportNameInvalid ||
      isTableInvalid ||
      selectedDateRange?.fromDate === null ||
      selectedDateRange?.toDate === null ||
      selectedRollUp === null ||
      (selectedRollUpCal === null && selectedRollUp !== "None") ||
      reportName === "" ||
      exportQueryDatas.length === 0
    ) {
      return;
    }

    setVisibleQueryReport(true);
    console.log("Query report clicked");
  };

  const openCreateTemplate = () => {
    setTemplate(emptyTemplate);
    setSubmittedCreateTemplate(false);
    setCreateTemplateDialog(true);
  };

  const hideCreateTemplate = () => {
    setSubmittedCreateTemplate(false);
    setCreateTemplateDialog(false);
  };

  const saveCreateTemplate = () => {
    setSubmittedCreateTemplate(true);
    if (template.templateName.trim() && template.description.trim()) {
      console.log(
        template,
        rawQueryData,
        selectedDateRange,
        selectedRollUp,
        selectedRollUpCal,
        exportQueryDatas
      );
      TemplateService.postCreateTemplate(
        template,
        rawQueryData,
        selectedDateRange,
        selectedRollUp,
        selectedRollUpCal,
        exportQueryDatas
      )
        .then((result) => {
          console.log("Template created successfully:", result);
          setCreateTemplateDialog(false);
          setTemplate(emptyTemplate);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "New Template Created",
            life: 3000,
          });
        })
        .catch((error) => {
          console.error("Error creating template:", error);
        });
    }
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _template = { ...template };

    _template[`${name}`] = val;

    setTemplate(_template);
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={hideCreateTemplate}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveCreateTemplate} />
    </React.Fragment>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="mr-2"
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteList(rowData)}
        />
      </React.Fragment>
    );
  };

  const onQueryDataChange = (data) => {
    setSelectedQueryDatas(getChildList(data, queryDatas));
    setRawQueryData(data);
  };

  const confirmDeleteList = (list) => {
    let key = list.key;
    const updatedQueryDatas = selectedQueryDatas.filter(
      (item) => item.key !== key
    );
    const updatedWantToExportDatas = exportQueryDatas.filter(
      (item) => item.key !== key
    );

    // handleSelectionChange({ value: updatedQueryDatas });
    handleSelectionChange({ value: updatedWantToExportDatas });
    setSelectedQueryDatas(updatedQueryDatas);

    const updatedRawQueryData = { ...rawQueryData };
    // updatedRawQueryData[key] = { checked: false, partialChecked: false };
    // if (updatedRawQueryData[key].checked === false) {
    const keys = key.split("-").map(Number);
    // keys.pop();
    let currentKey = "";
    for (const k of keys) {
      currentKey += (currentKey === "" ? "" : "-") + k;
      // if (updatedRawQueryData[currentKey]) {
      // Update existing object values
      const childrenKeys = Object.keys(updatedRawQueryData).filter(
        (childKey) => {
          return childKey.startsWith(currentKey + "-") && childKey !== key;
        }
      );
      if (childrenKeys.length > 0) {
        updatedRawQueryData[currentKey].checked = false;
        updatedRawQueryData[currentKey].partialChecked = true;

        if (
          childrenKeys.reduce(
            (acc, item) => acc + (item.split("-").length - 1),
            0
          ) === 1
        ) {
          delete updatedRawQueryData[currentKey];
        }
      } else {
        delete updatedRawQueryData[currentKey];
      }
    }
    // }

    setRawQueryData(updatedRawQueryData);
  };

  const handleSelectionChange = (e) => {
    console.log(e.value);
    setExportQueryDatas(e.value);
    // setVisiblePreviewTrend(true);
  };

  const getDisableSaveTemplate = () => {
    if (
      exportQueryDatas?.length < 1 ||
      selectedDateRange?.fromDate === null ||
      selectedDateRange?.toDate === null ||
      selectedRollUp === null ||
      (selectedRollUp !== "None" && selectedRollUpCal === null)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    // <NavBar>
    <div className="manual-report-container">
      <Toast ref={toast} />
      <div className="list-box-table-container flex gap-3">
        <QueryListBoxTable
          selectedQueryDatasLength={selectedQueryDatas?.length}
          queryDatas={queryDatas}
          selectedQueryDatas={rawQueryData}
          onQueryDataChange={onQueryDataChange}
          maxHeight={"620px"}
        ></QueryListBoxTable>
        <div className="manual-box card w-full flex flex-column gap-3">
          {/* Report Name */}
          <div className="description-container">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-file-edit" />
              <InputText
                placeholder="Name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                className={[
                  "w-full",
                  "p-inputtext-lg",
                  classNames({
                    "p-invalid": validationState.isReportNameInvalid,
                  }),
                ]}
              />
            </span>
          </div>

          {/* Date Range , AVG MIN MAX */}
          <div className="manual-box-header flex justify-content-between align-items-center">
            <FullDateRange
              onDateRangeChange={handleDateRangeChange}
              isDateRangeInvalid={validationState.isDateRangeInvalid}
              isRollupInvalid={validationState.isRollupInvalid}
              isRollupCalInvalid={
                selectedRollUpCal === null && selectedRollUp !== "None"
              }
              onRollUpChange={handleRollUpChange}
            ></FullDateRange>
          </div>

          {/* Query Data table */}
          <div className="manual-data-table-container">
            <DataTable
              className="manual-data-table"
              value={selectedQueryDatas}
              selection={exportQueryDatas}
              onSelectionChange={handleSelectionChange}
              dataKey="key"
              tableStyle={{ minWidth: "50rem" }}
              emptyMessage="Waiting for select query data"
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
              // Scroll
              scrollable
              scrollHeight="420px"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                className={` ${
                  validationState.isTableInvalid ? "invalid" : ""
                }`}
                headerClassName={` ${
                  validationState.isTableInvalid ? "invalid" : ""
                }`}
              ></Column>
              <Column
                header="No."
                headerStyle={{ width: "3rem" }}
                body={(data, options) => options.rowIndex + 1}
              ></Column>
              <Column field="data" header="Data name"></Column>
              <Column
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "5rem" }}
              ></Column>
            </DataTable>
          </div>

          {/* BTN preveie trend */}
          <div className="preview-trend-container h-3rem">
            <Button
              tooltip={
                exportQueryDatas?.length < 1
                  ? "Please select query data at least 1 item "
                  : ""
              }
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
                showOnDisabled: true,
              }}
              onClick={() => setVisiblePreviewTrend(true)}
              label="Preview trend"
              severity="info"
              className="w-full h-full"
              disabled={exportQueryDatas?.length < 1}
            />
          </div>

          {/* BTN Save Template */}
          <div className="save-template-container h-3rem">
            <Button
              tooltip={
                getDisableSaveTemplate()
                  ? "Please select query data at least 1 item and Time Range"
                  : ""
              }
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
                showOnDisabled: true,
              }}
              label="Save Template"
              className="w-full h-full"
              onClick={() => openCreateTemplate()}
              disabled={getDisableSaveTemplate()}
            />
          </div>

          {/* BTN Query Report */}
          <div className="query-report-container flex flex-auto justify-content-end align-items-end">
            <div>
              <Button
                label="Query report"
                severity="success"
                size="large"
                onClick={handleQueryReportClick}
              />
            </div>
          </div>
        </div>
        {/* Sidebar */}
        {/* <PreviewTrendSideBar
          visiblePreviewTrend={
            visiblePreviewTrend || exportQueryDatas.length > 0
          }
          exportQueryDatas={exportQueryDatas}
          dataTypeExport={{ name: "AVG" }}
        ></PreviewTrendSideBar> */}
      </div>

      {/* Dialog Creat Template */}
      <Dialog
        visible={createTemplateDialog}
        style={{ width: "40rem" }}
        breakpoints={{ "1000px": "75vw", "641px": "90vw" }}
        header="Template Detail"
        modal
        className="p-fluid create-template-dialog"
        footer={productDialogFooter}
        onHide={hideCreateTemplate}
      >
        {/* Template Name */}
        <div className="field">
          <label htmlFor="templateName" className="font-bold">
            Template Name
          </label>
          <InputText
            id="templateName"
            value={template.templateName}
            onChange={(e) => onInputChange(e, "templateName")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submittedCreateTemplate && !template.templateName,
            })}
          />
          {submittedCreateTemplate && !template.templateName && (
            <small className="p-error">Template Name is required.</small>
          )}
        </div>

        {/* Description*/}
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <InputTextarea
            id="description"
            value={template.description}
            onChange={(e) => onInputChange(e, "description")}
            required
            className={classNames({
              "p-invalid": submittedCreateTemplate && !template.description,
            })}
            rows={2}
          />
          {submittedCreateTemplate && !template.description && (
            <small className="p-error">Description is required.</small>
          )}
        </div>

        {/* wrapper of date info */}
        <div className="field dialog-box-create-template mb-5">
          <div className="header">
            <span className="text select-none">Time Range</span>
          </div>
          <div className="flex justify-content-center align-items-end gap-5 mb-3 text-xl">
            <div className="flex gap-4 flex-wrap justify-content-center">
              <div className="flex justify-content-center align-items-center gap-2">
                <label className="text-l">From : </label>
                <div>{formatDateDMY(selectedDateRange?.fromDate)}</div>
              </div>
              <div className="flex justify-content-center align-items-center gap-2">
                <label className="text-l">To : </label>
                <div>{formatDateDMY(selectedDateRange?.toDate)}</div>
              </div>
              <div className="flex justify-content-center align-items-center gap-2">
                <label className="text-l">Rollup : </label>
                <div>{selectedRollUp}</div>
              </div>
              {selectedRollUp !== "None" && (
                <div className="flex justify-content-center align-items-center gap-2">
                  <label className="text-l">Type : </label>
                  <div>{getAggregationType(selectedRollUpCal)}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Query Data */}
        <div className="field dialog-box-create-template">
          <div>
            <div className="header">
              <span className="text select-none">Query Data</span>
            </div>
          </div>
          <div className="flex overflow-scroll flex-wrap justify-content-center align-items-start h-auto">
            {exportQueryDatas.map((item) => (
              <span className="query-data-dialog text-center" key={item.key}>
                {item.data}
              </span>
            ))}
          </div>
        </div>
      </Dialog>

      {/* Side bar Preview Trend */}
      <PreviewTrendSideBar
        visiblePreviewTrend={visiblePreviewTrend}
        setVisiblePreviewTrend={setVisiblePreviewTrend}
        exportQueryDatas={exportQueryDatas}
      ></PreviewTrendSideBar>

      {/* Side bar Query Report */}
      <QueryReportSideBar
        visibleQueryReport={visibleQueryReport}
        setVisibleQueryReport={setVisibleQueryReport}
        selectedDateRange={selectedDateRange}
        selectedRollUp={selectedRollUp}
        selectedRollUpCal={selectedRollUpCal}
        dataTypeExport={{ name: "AVG" }}
        reportName={reportName}
        exportQueryDatas={exportQueryDatas}
      ></QueryReportSideBar>
    </div>
    // </NavBar>
  );
};
