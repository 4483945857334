import axios from "axios";

export const DatabasStateService = {
  getDatabaseStates(resolve, reject) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(process.env.REACT_APP_API_URL + "/api/database-state", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getDBState() {
    return new Promise((resolve, reject) => {
      this.getDatabaseStates(resolve, reject);
    });
  },
};
