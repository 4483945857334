export const DateService = {
  changeStringDateBudhistToDateObj(dateStr) {
    if (dateStr) {
      // 26/05/2530
      let dateArray = dateStr.split("/");
      let year = Number(dateArray[2]) - 543;
      let dateChangeFormat = year + "-" + dateArray[1] + "-" + dateArray[0];
      return new Date(dateChangeFormat);
    } else {
      return new Date();
    }
  },

  changeStringDateBudhistToDateObjForVerify(dateStr) {
    if (dateStr) {
      // 26/05/2530
      let dateArray = dateStr.split("/");
      let year = Number(dateArray[2]) - 543;
      let dateChangeFormat = year + "-" + dateArray[1] + "-" + dateArray[0];
      return new Date(dateChangeFormat);
    } else {
      return undefined;
    }
  },

  changeDateStringToDateBudhist(dateStr) {
    if (dateStr) {
      // "2011-11-23T00:00:00.000Z"
      let dateFormal = new Date(dateStr);
      let options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return dateFormal.toLocaleDateString("th-TH", options);
    } else {
      return "";
    }
  },

  changeDateStringToDateFormal(dateStr) {
    if (dateStr) {
      // "2011-11-23T00:00:00.000Z"
      let date = new Date(dateStr);
      return (
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getFullYear() + 543)
      );
    } else {
      return "";
    }
  },

  changeDateStringToTimeFormal(dateStr) {
    if (dateStr) {
      // "2011-11-23T00:00:00.000Z"
      let date = new Date(dateStr);
      let arr = date.toLocaleTimeString("th-TH").split(":");
      let hour = arr[0];
      let min = arr[1];
      return hour + ":" + min;
    } else {
      return "";
    }
  },

  changeDateStringToTimeFormalISO(dateStr) {
    if (dateStr) {
      // "2011-11-23T00:00:00.000Z"
      let date = new Date(dateStr);
      let arr = date.toISOString().split("T")[1];
      let arrT = arr.split(":");
      let hour = arrT[0];
      let min = arrT[1];
      return hour + ":" + min;
    } else {
      return "";
    }
  },
};
