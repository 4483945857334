import React from "react";
import { Button } from "primereact/button";

export const ExportButtonsTable = ({ exportCSV, exportExcel, exportPdf }) => (
  <div className="flex align-items-center justify-content-end gap-2 export-buttons">
    <Button
      type="button"
      icon="pi pi-file"
      rounded
      onClick={() => exportCSV(false)}
      tooltip="CSV"
      tooltipOptions={{ position: "bottom" }}
    />
    <Button
      type="button"
      icon="pi pi-file-excel"
      severity="success"
      rounded
      onClick={exportExcel}
      tooltip="XLS"
      tooltipOptions={{ position: "bottom" }}
    />
    <Button
      type="button"
      icon="pi pi-file-pdf"
      severity="warning"
      rounded
      onClick={exportPdf}
      tooltip="PDF"
      tooltipOptions={{ position: "bottom" }}
    />
  </div>
);

export const ExportButtonsChart = ({ exportPNG, exportSVG }) => (
  <div className="header-export-btn flex align-items-center justify-content-end gap-2">
    <Button
      type="button"
      severity="help"
      icon="pi pi-image"
      rounded
      onClick={exportPNG}
      tooltip="PNG"
      tooltipOptions={{ position: "bottom" }}
    />
    <Button
      type="button"
      icon="pi pi-file"
      severity="danger"
      rounded
      onClick={exportSVG}
      tooltip="SVG"
      tooltipOptions={{ position: "bottom" }}
    />
  </div>
);
