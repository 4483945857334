import axios from "axios";
export const TemplateService = {
  // GET All data template
  getTemplatesData(resolve, reject) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(process.env.REACT_APP_API_URL + `/api/templates?sort=id`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getTemplates() {
    return new Promise((resolve, reject) => {
      this.getTemplatesData(resolve, reject);
    });
  },

  // Get List all 'name' template
  getAllNameTemplate(resolve, reject) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(process.env.REACT_APP_API_URL + `/api/templates/list-all-name`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        console.log(response);
        resolve(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getAllNameTemplates() {
    return new Promise((resolve, reject) => {
      this.getAllNameTemplate(resolve, reject);
    });
  },

  // Get template by ID
  getTemplateByID(resolve, reject, id) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(process.env.REACT_APP_API_URL + `/api/templates/` + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        console.log(response);
        resolve(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getTemplate(id) {
    return new Promise((resolve, reject) => {
      this.getTemplateByID(resolve, reject, id);
    });
  },

  // POST Create Template
  postCreateTemplate: async (
    template,
    rawQueryData,
    selectedDateRange,
    selectedRollUp,
    selectedRollUpCal,
    exportQueryDatas
  ) => {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    try {
      const payload = {
        data: {
          name: template.templateName,
          description: template.description,
          startDate: selectedDateRange.fromDate,
          endDate: selectedDateRange.toDate,
          rollup: selectedRollUp,
          type: selectedRollUpCal,
          queryData: rawQueryData,
          exportQueryData: exportQueryDatas,
        },
      };

      const headers = {
        Authorization: "Bearer " + token,
      };

      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/api/templates",
        payload,
        { headers }
      );

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  },

  // PUT Template
  putEditTemplate: async (
    id,
    template,
    rawQueryData,
    selectedDateRange,
    selectedRollUp,
    selectedRollUpCal,
    exportQueryDatas
  ) => {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    try {
      const payload = {
        data: {
          name: template.templateName,
          description: template.description,
          startDate: selectedDateRange.fromDate,
          endDate: selectedDateRange.toDate,
          rollup: selectedRollUp,
          type: selectedRollUpCal,
          queryData: rawQueryData,
          exportQueryData: exportQueryDatas,
        },
      };

      const headers = {
        Authorization: "Bearer " + token,
      };
      console.log(payload);
      const res = await axios.put(
        process.env.REACT_APP_API_URL + "/api/templates/" + id,
        payload,
        { headers }
      );

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  },
};
