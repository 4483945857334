import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { QueryListBoxTable } from "../../components/query-list-box/QueryListBoxTable";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { FullDateRange } from "../../../src/components/table-header/filter/date-range/FullDateRange";

export const NewTemplate = ({
  queryDatas,
  isValidCreatTemplate,
  selectedtemplate,
  setSelectedtemplate,
  rawQueryData,
  onQueryDataChange,
  selectedQueryDatas,
  exportQueryDatas,
  handleSelectionChange,
  actionBodyTemplate,
  setVisiblePreviewTrend,
  savecreateTemplateFromNewTemplate,
  handleDateRangeChange,
  validationState,
  selectedRollUpCal,
  selectedRollUp,
  handleRollUpChange,
}) => {
  return (
    <div className={`card-template flex gap-3 flex-column w-full`}>
      <div className="flex gap-4 flex-column">
        <span className="p-float-label">
          <InputText
            id="create-template-name"
            className={[
              "create-template-name-input",
              classNames({
                "p-invalid":
                  isValidCreatTemplate && !selectedtemplate.templateName,
              }),
            ]}
            value={selectedtemplate?.templateName}
            onChange={(e) =>
              setSelectedtemplate((prev) => ({
                ...prev,
                templateName: e.target.value,
              }))
            }
          />
          <label htmlFor="create-template-name">Template Name</label>
        </span>

        <span className="p-float-label">
          <InputTextarea
            rows={1}
            cols={49}
            id="create-template-description"
            className={[
              classNames({
                "p-invalid":
                  isValidCreatTemplate && !selectedtemplate.templateName,
              }),
            ]}
            value={selectedtemplate?.description}
            onChange={(e) =>
              setSelectedtemplate((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
          <label htmlFor="create-template-description">Description</label>
        </span>
      </div>

      {/* Date Range , AVG MIN MAX */}
      <div className="manual-box-header flex justify-content-between align-items-center">
        <FullDateRange
          onDateRangeChange={handleDateRangeChange}
          isDateRangeInvalid={validationState.isDateRangeInvalid}
          isRollupInvalid={validationState.isRollupInvalid}
          isRollupCalInvalid={
            selectedRollUpCal === null && selectedRollUp !== "None"
          }
          onRollUpChange={handleRollUpChange}
        ></FullDateRange>
      </div>

      <div className="flex gap-3 ">
        <QueryListBoxTable
          selectedQueryDatasLength={selectedQueryDatas?.length}
          queryDatas={queryDatas}
          selectedQueryDatas={rawQueryData}
          onQueryDataChange={onQueryDataChange}
          maxHeight={"500px"}
        ></QueryListBoxTable>
        <div className="manual-box card w-full flex flex-column gap-3">
          {/* Query Data table */}
          <div className="manual-data-table-container">
            <DataTable
              className="manual-data-table"
              value={selectedQueryDatas}
              selection={exportQueryDatas}
              onSelectionChange={handleSelectionChange}
              dataKey="key"
              tableStyle={{ minWidth: "50rem" }}
              emptyMessage="Waiting for select query data"
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
              // Scroll
              scrollable
              scrollHeight="420px"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                className={` ${
                  isValidCreatTemplate && exportQueryDatas?.length <= 0
                    ? "invalid"
                    : ""
                }`}
                headerClassName={` ${
                  isValidCreatTemplate && exportQueryDatas?.length <= 0
                    ? "invalid"
                    : ""
                }`}
              ></Column>
              <Column
                header="No."
                headerStyle={{ width: "3rem" }}
                body={(data, options) => options.rowIndex + 1}
              ></Column>
              <Column field="data" header="Data name"></Column>
              <Column
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "5rem" }}
              ></Column>
            </DataTable>
          </div>

          {/* BTN preveie trend */}
          <Button
            tooltip={
              exportQueryDatas?.length < 1
                ? "Please select query data at least 1 item "
                : ""
            }
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
              showOnDisabled: true,
            }}
            onClick={() => setVisiblePreviewTrend(true)}
            label="Preview trend"
            severity="info"
            className="w-full h-full"
            disabled={exportQueryDatas?.length < 1}
          />
          <div className="query-report-container flex flex-auto justify-content-end align-items-end">
            <Button
              severity="success"
              label="Create Template"
              size="large"
              onClick={savecreateTemplateFromNewTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
