import axios from "axios";

export const ListService = {
  // GET List
  getReportsData(resolve, reject, page, pageSize) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/list-reports?sort=id&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getReports(page, pageSize) {
    return new Promise((resolve, reject) => {
      this.getReportsData(resolve, reject, page, pageSize);
    });
  },

  // GET List by filter
  getReportsDataByFilter(resolve, reject, page, pageSize, startDate, endDate) {
    const { token } = JSON.parse(localStorage.getItem("userData")) || {};
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/list-reports?sort=id&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[createdAt][$gte]=${startDate}&filters[createdAt][$lte]=${endDate}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  },

  getReport(page, pageSize, startDate, endDate) {
    return new Promise((resolve, reject) => {
      this.getReportsDataByFilter(
        resolve,
        reject,
        page,
        pageSize,
        startDate,
        endDate
      );
    });
  },

  // DEL List
  postDelReports: async (ids) => {
    console.log(process.env.REACT_APP_API_URL);
    try {
      const idArray = Array.isArray(ids) ? ids : [ids];
      const { token } = JSON.parse(localStorage.getItem("userData")) || {};

      for (const id of idArray) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/list-reports/${id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log(`Report with ID ${id} deleted successfully.`);
      }
    } catch (error) {
      throw error;
    }
  },
};
